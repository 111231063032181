import React from "react";
import "./Logout.css";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  const handlelogout = () => {
    localStorage.removeItem("atkn");
    navigate("/");
  };

  return (
    <div className="Logout_button" onClick={handlelogout}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M11 1C16.52 1 21 5.48 21 11C21 16.52 16.52 21 11 21C5.48 21 1 16.52 1 11"
            stroke="#7C7C7D"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 10.0008L1.8 1.80078"
            stroke="#7C7C7D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.00008 5.83V1H5.83008"
            stroke="#7C7C7D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
      Logout
    </div>
  );
};
export default Logout;
