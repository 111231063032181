import axios from "axios";

const getToken = () => {
  return localStorage.getItem("atkn");
};

const headers = () => {
  const token = getToken();
  return {
    headers: {
      Authorization: `Bearer ${"" + token}`,
    },
  };
};

const token = async (id: string) => {
  const params = {
    device_id: id,
    device_type: "Web",
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL_AUTH}getToken`,
      params
    );
    console.log(response.data.data.token);
    return response.data.data.token;
  } catch (error: any) {
    throw error.response.data;
  }
};
interface Value {
  password: string;
  email: string;
}

const login = async (value: Value) => {
  const params = {
    Email: value.email,
    Password: value.password,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL_AUTH}signin`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const getmaster = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}common/get-master`,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
// const forgetpassword = async (data) => {
//   const params = new FormData();
//   params.append("email", data.email);

//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}ForgotOtpApi`,
//       params
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const otpverify = async (data, token) => {
//   const otpno = Number(data);
//   const params = {
//     otp: otpno,
//   };

//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}Otpverification`,
//       params,
//       {
//         headers: {
//           Authentication: token,
//         },
//       }
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const resetpassword = async (data, token) => {
//   const params = new FormData();
//   params.append("new_password", data);

//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}NewpasswordApi`,
//       params,
//       {
//         headers: {
//           Authentication: token,
//         },
//       }
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

export default token;
export {
  login,
  getmaster,
  // forgetpassword, otpverify, resetpassword
};
