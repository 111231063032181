import { Link, NavLink } from "react-router-dom";
import lng from "../../assets/lang setting.png";
import theme from "../../assets/theme setting.png";
import nf from "../../assets/notification setting.png";
import ac from "../../assets/ac setting.png";
import mail from "../../assets/changeemail setting.png";
import cp from "../../assets/changepassword setting.png";
import location from "../../assets/location setting.png";
import deleteimg from "../../assets/delete.png";
import "./Settingmenu.css";

const Settingmenu = () => {
  return (
    <>
      <div className="Setting_sidemenu">
        <div className="SettingbarItem">
          <img src={lng} alt="lng" />
          <p>Language</p>
        </div>
        <div className="SettingbarItem">
          <img src={theme} alt="theme" />
          <p>Theme</p>
        </div>
        <div className="SettingbarItem">
          <img src={ac} alt="ac setting" />
          <p>Account Setting</p>
        </div>
        <NavLink to="/Setting/Notification" className="SettingbarItem">
          <img src={nf} alt="nf" />
          <p>Notification</p>
        </NavLink>
        <div className="SettingbarItem">
          <img src={cp} alt="cp" />
          <p>Change Password</p>
        </div>
        <div className="SettingbarItem">
          <img src={mail} alt="email" />
          <p>Change Email</p>
        </div>
        <div className="SettingbarItem">
          <img src={location} alt="location" />
          <p>Add Location</p>
        </div>
        <div className="SettingbarItem">
          <img src={deleteimg} alt="delete" />
          <p>Delete Account</p>
        </div>
      </div>
    </>
  );
};
export default Settingmenu;
