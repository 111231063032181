import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";

import Header from "../../Layout/Header";
import projectimg from "../../assets/Projectimg.png";
import myprofile from "../../assets/myprofile.png";
import review1 from "../../assets/Review1.png";
import usa from "../../assets/usa.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Userdetails = () => {
  const [list, setlist] = useState<any>([]);

  const location = useLocation();
  const state = location.state as { id: string };
  const navigate = useNavigate();

  useEffect(() => {
    getdetails();
  }, []);

  const getdetails = async () => {
    try {
      // const response = await userdetail(id);
      // console.log(response);
      // if (response.status === true) {
      //   setlist(response.data);
      // } else {
      //   setlist([]);
      // }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">
          <svg
            width="17"
            height="16"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => navigate("/Users")}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83331 9H20.1666"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          User Details
        </h1>
        <Header />
      </div>
      <div className="Dashboard_screen">
        <div className="Dashboard_main">
          <div className="Dashboard_left">
            <div className="agency_imgdata">
              <img src={myprofile} alt="profile" />
              <div className="agency_agencyinfo">
                <div className="agency_agencyleft">
                  <h1 className="agency_name">Abdullah Masur</h1>
                  <div className="agency_location">
                    <p>
                      <svg
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.34147 7.42513C9.34147 6.2743 8.40897 5.3418 7.25814 5.3418C6.1073 5.3418 5.1748 6.2743 5.1748 7.42513C5.1748 8.57596 6.1073 9.50846 7.25814 9.50846C8.40897 9.50846 9.34147 8.57596 9.34147 7.42513Z"
                          stroke="#2C7873"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.25 16C4.83417 16 1 11.7992 1 7.3325C1 3.835 3.7975 1 7.25 1C10.7017 1 13.5 3.835 13.5 7.3325C13.5 11.7992 9.66583 16 7.25 16Z"
                          stroke="#2C7873"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      United State
                    </p>
                    <p>
                      <svg
                        width="25"
                        height="30"
                        viewBox="0 -5 25 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_673_632)">
                          <path
                            d="M17.3524 12.1225L17.6449 14.4925C17.7199 15.115 17.0524 15.55 16.5199 15.2275L13.3774 13.36C13.0324 13.36 12.6949 13.3375 12.3649 13.2925C12.9199 12.64 13.2499 11.815 13.2499 10.9225C13.2499 8.79251 11.4049 7.06754 9.12489 7.06754C8.25489 7.06754 7.45239 7.31502 6.78489 7.75002C6.76239 7.56252 6.75488 7.37501 6.75488 7.18001C6.75488 3.7675 9.71739 1 13.3774 1C17.0374 1 19.9999 3.7675 19.9999 7.18001C19.9999 9.20501 18.9574 10.9975 17.3524 12.1225Z"
                            stroke="#FF3868"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.25 10.9214C13.25 11.8139 12.92 12.6389 12.365 13.2914C11.6225 14.1914 10.445 14.7689 9.12501 14.7689L7.16751 15.9314C6.8375 16.1339 6.4175 15.8564 6.4625 15.4739L6.65 13.9964C5.645 13.2989 5 12.1814 5 10.9214C5 9.60138 5.70501 8.43889 6.78501 7.74889C7.45251 7.31389 8.25501 7.06641 9.12501 7.06641C11.405 7.06641 13.25 8.79138 13.25 10.9214Z"
                            stroke="#FF3868"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_673_632"
                            x="0.25"
                            y="0.25"
                            width="24.5"
                            height="24.5039"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_673_632"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_673_632"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                      Speaks English, French, Arabic
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Dashboard_titlediv">
              <h3>Recents Projects</h3>
              <p>view all</p>
            </div>
            <div className="Agency_project">
              <img src={projectimg} alt="" />
              <img src={projectimg} alt="" />
              <img src={projectimg} alt="" />
              <img src={projectimg} alt="" />
            </div>

            <div className="Dashboard_titlediv">
              <h3>Recent Reviews</h3>
              <p>Most Resent</p>
            </div>
            <div className="profilepage_ratingdiv ">
              <img
                alt="imgs"
                src={review1}
                className="agency_reviewprofileimg"
              />
              <div>
                <h4 className="agency_reviews_name">Monalissa Hamlin</h4>
                <div className="agency_review_details">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Rating
                      name="read-only"
                      value={5}
                      size="small"
                      style={{ position: "initial", fontSize: "18px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Box>
                  |<p>2 days ago</p>|
                  <p className="agency_review_map">
                    <img src={usa} />
                    USA
                  </p>
                </div>
                <p className="agency_info">
                  Lorem ipsum dolor sit amet consectetur. Id interdum vulputate
                  nisl tortor. Eget mattis vitae sed eget. Justo turpis urna qu
                </p>
              </div>
            </div>
            <div className="profilepage_ratingdiv ">
              <img
                alt="imgs"
                src={review1}
                className="agency_reviewprofileimg"
              />
              <div>
                <h4 className="agency_reviews_name">Monalissa Hamlin</h4>
                <div className="agency_review_details">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Rating
                      name="read-only"
                      value={5}
                      size="small"
                      style={{ position: "initial", fontSize: "18px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Box>
                  |<p>2 days ago</p>|
                  <p className="agency_review_map">
                    <img src={usa} />
                    USA
                  </p>
                </div>
                <p className="agency_info">
                  Lorem ipsum dolor sit amet consectetur. Id interdum vulputate
                  nisl tortor. Eget mattis vitae sed eget. Justo turpis urna qu
                </p>
              </div>
            </div>
            <div className="profilepage_ratingdiv ">
              <img
                alt="imgs"
                src={review1}
                className="agency_reviewprofileimg"
              />
              <div>
                <h4 className="agency_reviews_name">Monalissa Hamlin</h4>
                <div className="agency_review_details">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Rating
                      name="read-only"
                      value={5}
                      size="small"
                      style={{ position: "initial", fontSize: "18px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Box>
                  |<p>2 days ago</p>|
                  <p className="agency_review_map">
                    <img src={usa} />
                    USA
                  </p>
                </div>
                <p className="agency_info">
                  Lorem ipsum dolor sit amet consectetur. Id interdum vulputate
                  nisl tortor. Eget mattis vitae sed eget. Justo turpis urna qu
                </p>
              </div>
            </div>
            <p className="agency_review_view">VIEW ALL REVIEWS</p>
          </div>
          <div className="Dashboard_right">
            <div className="agency_allreview">
              <div className="Dashboard_titlediv">
                <h3>Reviews</h3>
              </div>
              <div className="agency_allreview_list">
                <div className="agency_allreview_details">
                  <h1>5.0</h1>
                  <p>15786 Reviews</p>
                </div>
                <div className="agency_review_stardetail">
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={5}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    1500
                  </p>
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={4}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    10
                  </p>
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={3}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    2
                  </p>
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={2}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    2
                  </p>
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={1}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    0
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Userdetails;
