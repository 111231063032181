import Header from "../../Layout/Header";
import "./Agencypreview.css";
import navlogo from "../../assets/Navlogo.png";
import map from "../../assets/map.png";
import agentimg from "../../assets/myprofile.png";
import { ChangeEvent, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { agencyreviewdetail, changestatus } from "../../service/Mainservice";
import { useLocation, useNavigate } from "react-router-dom";
import Map from "./Map";
import Logout from "../../Layout/Logout";

const Agencypreview = () => {
  const [field, setField] = useState<
    { Module_Name: string; Module_Message: string }[]
  >([]);
  const [list, setlist] = useState<any>({});
  const location = useLocation();
  const navigate = useNavigate();
  const [address, setAddress] = useState<string>("");
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLngLiteral | null>({
      lat: 0,
      lng: 0,
    });
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>({
    lat: 0,
    lng: 0,
  });
  useEffect(() => {
    getrequestlist(location.state.id);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (checked && value.trim() !== "") {
      setField([
        ...field,
        { Module_Name: value, Module_Message: getErrorMessage(value) },
      ]);
    } else {
      setField(field.filter((item) => item.Module_Name !== value));
    }
  };
  const getErrorMessage = (name: string) => {
    switch (name) {
      case "crnumber":
        return " CR number doesn’t match CR document";
      default:
        return "";
    }
  };

  const handleMsgChange = (name: string, newMsg: string) => {
    const updatedField = field.map((item) =>
      item.Module_Name === name ? { ...item, Module_Message: newMsg } : item
    );
    setField(updatedField);
  };

  const getrequestlist = async (id: string) => {
    try {
      const response = await agencyreviewdetail(id);
      console.log(response);
      if (response.status === true) {
        setlist(response.data);
        setAddress(response.data.address);
        setMarkerPosition({
          lat: Number(response.data.latitude),
          lng: Number(response.data.longitude),
        });
        setCenter({
          lat: Number(response.data.latitude),
          lng: Number(response.data.longitude),
        });
      } else {
        setlist([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handledownload = (response: string) => {
    const a = document.createElement("a");
    a.href = response;
    a.target = "_blank";
    // Simulate a click on the anchor element to trigger the download
    a.click();
  };
  console.log(field);

  const handlestatus = async (id: string, status: string, field: any) => {
    try {
      console.log("Trigger");
      const response = await changestatus(id, status, field);
      console.log(response);
      navigate("/Dashboard");
    } catch (error: any) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">
          {" "}
          <svg
            width="17"
            height="16"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => navigate("/Requests")}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83331 9H20.1666"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Pending Request
        </h1>
        <Logout />
      </div>
      <div className="Dashboard_screen">
        <div className="Agency_box">
          <div className="preview_form_titlediv">
            <h3 className="preview_form_title">Request History</h3>
          </div>
          <table className="preview_history_table">
            <tr>
              <th>Attemp</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
            <tr>
              <td>2</td>
              <td>2024-05-15 12:00pm</td>
              <td>Pending</td>
            </tr>
            <tr>
              <td>1</td>
              <td>2024-05-11 12:00pm</td>
              <td>Rejected</td>
            </tr>
          </table>
          <div className="preview_form_titlediv">
            <h3 className="preview_form_title">Commercial Register (CR)</h3>
            <svg
              width="307"
              height="2"
              viewBox="0 0 397 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="preview_line"
            >
              <path d="M0 1L397 0.999965" stroke="#EDEDED" />
            </svg>
          </div>
          <p className="auth_inputfield">{list?.cr_name_english}</p>
          <p className="auth_inputfield">{list?.cr_name_arabic}</p>
          <div className="preview_passwordfield">
            <div className="preview_invaliddiv">
              <div className="preview_invalidcheck">
                <label
                  htmlFor="crnumber"
                  className={
                    field.some((f) => f.Module_Name === "crnumber")
                      ? "auth_inputfield selected_invalid"
                      : "auth_inputfield"
                  }
                >
                  {list?.cr_no}
                </label>
                <Tooltip
                  title={
                    field.some((f) => f.Module_Name === "crnumber")
                      ? ""
                      : "mark as incorrect detail"
                  }
                  placement="top-start"
                  sx={{
                    backgroundColor: "rgba(255, 56, 103, 0.114)",
                    color: "#FF3868",
                  }}
                >
                  <input
                    type="checkbox"
                    value="crnumber"
                    name="crnumber"
                    onChange={handleChange}
                  />
                </Tooltip>
              </div>
              {field.some((f) => f.Module_Name === "crnumber") && (
                <input
                  type="text"
                  name="crnumbermsg"
                  className="invalid_errormsg"
                  value={
                    field.find((f) => f.Module_Name === "crnumber")
                      ?.Module_Message || ""
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleMsgChange("crnumber", e.target.value)
                  }
                />
              )}
            </div>
            <div className="preview_invaliddiv">
              <div className="preview_invalidcheck">
                <label
                  htmlFor="crexpirydate"
                  className={
                    field.some((f) => f.Module_Name === "crexpirydate")
                      ? "auth_inputfield selected_invalid"
                      : "auth_inputfield"
                  }
                >
                  {list?.cr_expiry}
                </label>
                <Tooltip
                  title={
                    field.some((f) => f.Module_Name === "crexpirydate")
                      ? ""
                      : "mark as incorrect detail"
                  }
                  placement="top-start"
                  sx={{
                    backgroundColor: "rgba(255, 56, 103, 0.114)",
                    color: "#FF3868",
                  }}
                >
                  <input
                    type="checkbox"
                    value="crexpirydate"
                    name="crexpirydate"
                    onChange={handleChange}
                  />
                </Tooltip>
              </div>
              {field.some((f) => f.Module_Name === "crexpirydate") && (
                <input
                  type="text"
                  name="crexpirydatemsg"
                  className="invalid_errormsg"
                  value={
                    field.find((f) => f.Module_Name === "crexpirydate")
                      ?.Module_Message || ""
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleMsgChange("crexpirydate", e.target.value)
                  }
                />
              )}
            </div>
          </div>
          <div className="preview_form_titlediv">
            <h3 className="preview_form_title">
              Certified Translation License
            </h3>
            <svg
              width="307"
              height="2"
              viewBox="0 0 397 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="preview_line"
            >
              <path d="M0 1L397 0.999965" stroke="#EDEDED" />
            </svg>
          </div>
          <div className="preview_passwordfield">
            <div className="preview_invaliddiv">
              <div className="preview_invalidcheck">
                <label
                  htmlFor="liecencenumber"
                  className={
                    field.some((f) => f.Module_Name === "liecencenumber")
                      ? "auth_inputfield selected_invalid"
                      : "auth_inputfield"
                  }
                >
                  {list?.license_no}
                </label>
                <Tooltip
                  title={
                    field.some((f) => f.Module_Name === "liecencenumber")
                      ? ""
                      : "mark as incorrect detail"
                  }
                  placement="top-start"
                  sx={{
                    backgroundColor: "rgba(255, 56, 103, 0.114)",
                    color: "#FF3868",
                  }}
                >
                  <input
                    type="checkbox"
                    value="liecencenumber"
                    name="liecencenumber"
                    onChange={handleChange}
                  />
                </Tooltip>
              </div>
              {field.some((f) => f.Module_Name === "liecencenumber") && (
                <input
                  type="text"
                  name="liecencenumbermsg"
                  className="invalid_errormsg"
                  value={
                    field.find((f) => f.Module_Name === "liecencenumber")
                      ?.Module_Message || ""
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleMsgChange("liecencenumber", e.target.value)
                  }
                />
              )}
            </div>
            <div className="preview_invaliddiv">
              <div className="preview_invalidcheck">
                <label
                  htmlFor="licenceexpirydate"
                  className={
                    field.some((f) => f.Module_Name === "licenceexpirydate")
                      ? "auth_inputfield selected_invalid"
                      : "auth_inputfield"
                  }
                >
                  {list?.license_expiry}
                </label>
                <Tooltip
                  title={
                    field.some((f) => f.Module_Name === "licenceexpirydate")
                      ? ""
                      : "mark as incorrect detail"
                  }
                  placement="top-start"
                  sx={{
                    backgroundColor: "rgba(255, 56, 103, 0.114)",
                    color: "#FF3868",
                  }}
                >
                  <input
                    type="checkbox"
                    value="licenceexpirydate"
                    name="licenceexpirydate"
                    onChange={handleChange}
                  />
                </Tooltip>
              </div>
              {field.some((f) => f.Module_Name === "licenceexpirydate") && (
                <input
                  type="text"
                  name="licenceexpirydatemsg"
                  className="invalid_errormsg"
                  value={
                    field.find((f) => f.Module_Name === "licenceexpirydate")
                      ?.Module_Message || ""
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleMsgChange("licenceexpirydate", e.target.value)
                  }
                />
              )}
            </div>
          </div>
          <div className="preview_form_titlediv">
            <h3 className="preview_form_title">Bank Details</h3>
            <svg
              width="307"
              height="2"
              viewBox="0 0 397 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="preview_line"
            >
              <path d="M0 1L397 0.999965" stroke="#EDEDED" />
            </svg>
          </div>
          <div className="preview_passwordfield">
            <div className="preview_invaliddiv">
              <div className="preview_invalidcheck">
                <label
                  htmlFor="bank_name"
                  className={
                    field.some((f) => f.Module_Name === "bank_name")
                      ? "auth_inputfield selected_invalid"
                      : "auth_inputfield"
                  }
                >
                  {list?.bank_name}
                </label>
                <Tooltip
                  title={
                    field.some((f) => f.Module_Name === "bank_name")
                      ? ""
                      : "mark as incorrect detail"
                  }
                  placement="top-start"
                  sx={{
                    backgroundColor: "rgba(255, 56, 103, 0.114)",
                    color: "#FF3868",
                  }}
                >
                  <input
                    type="checkbox"
                    value="bank_name"
                    name="bank_name"
                    onChange={handleChange}
                  />
                </Tooltip>
              </div>
              {field.some((f) => f.Module_Name === "bank_name") && (
                <input
                  type="text"
                  name="bank_namemsg"
                  className="invalid_errormsg"
                  value={
                    field.find((f) => f.Module_Name === "bank_name")
                      ?.Module_Message || ""
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleMsgChange("bank_name", e.target.value)
                  }
                />
              )}
            </div>
            <div className="preview_invaliddiv">
              <div className="preview_invalidcheck">
                <label
                  htmlFor="bank_ibanNo"
                  className={
                    field.some((f) => f.Module_Name === "bank_ibanNo")
                      ? "auth_inputfield selected_invalid"
                      : "auth_inputfield"
                  }
                >
                  {list?.bank_iban_no}
                </label>
                <Tooltip
                  title={
                    field.some((f) => f.Module_Name === "bank_ibanNo")
                      ? ""
                      : "mark as incorrect detail"
                  }
                  placement="top-start"
                  sx={{
                    backgroundColor: "rgba(255, 56, 103, 0.114)",
                    color: "#FF3868",
                  }}
                >
                  <input
                    type="checkbox"
                    value="bank_ibanNo"
                    name="bank_ibanNo"
                    onChange={handleChange}
                  />
                </Tooltip>
              </div>
              {field.some((f) => f.Module_Name === "bank_ibanNo") && (
                <input
                  type="text"
                  name="bank_ibanNomsg"
                  className="invalid_errormsg"
                  value={
                    field.find((f) => f.Module_Name === "bank_ibanNo")
                      ?.Module_Message || ""
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleMsgChange("bank_ibanNo", e.target.value)
                  }
                />
              )}
            </div>
          </div>
          <div className="preview_form_titlediv">
            <h3 className="preview_form_title">Location</h3>
            <svg
              width="307"
              height="2"
              viewBox="0 0 397 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="preview_line"
            >
              <path d="M0 1L397 0.999965" stroke="#EDEDED" />
            </svg>
          </div>
          <div className="preview_passwordfield">
            <p className="auth_inputfield">{list?.country_detail?.name} </p>
          </div>
          <div className="preview_passwordfield">
            <p className="auth_inputfield">{list?.state_detail?.name}</p>

            <p className="auth_inputfield">{list?.city_detail?.name}</p>
          </div>
          <Map
            address={address}
            setAddress={setAddress}
            markerPosition={markerPosition}
            setMarkerPosition={setMarkerPosition}
            center={center}
          />

          <div className="preview_form_titlediv">
            <h3 className="preview_form_title">Documents</h3>
            <svg
              width="307"
              height="2"
              viewBox="0 0 397 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="preview_line"
            >
              <path d="M0 1L397 0.999965" stroke="#EDEDED" />
            </svg>
          </div>
          <div className="preview_documents">
            <img src={agentimg} alt="" />
            <div className="preview_document_div">
              <p className="preview_documentfield">
                our_CR.PDF{" "}
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handledownload(list?.commercial_certificate)}
                >
                  <path
                    d="M12.7305 5.73242C15.7621 5.98274 17 7.47657 17 10.7468V10.8518C17 14.4612 15.4926 15.9066 11.7284 15.9066H6.24627C2.48205 15.9066 0.97467 14.4612 0.97467 10.8518V10.7468C0.97467 7.50079 2.19573 6.00696 5.17679 5.7405"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.99149 10.6578V1.46875"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8125 3.26949L8.99144 0.564453L6.17038 3.26949"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
              <p className="preview_documentfield">
                our_license.PDF{" "}
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handledownload(list?.translation_license)}
                >
                  <path
                    d="M12.7305 5.73242C15.7621 5.98274 17 7.47657 17 10.7468V10.8518C17 14.4612 15.4926 15.9066 11.7284 15.9066H6.24627C2.48205 15.9066 0.97467 14.4612 0.97467 10.8518V10.7468C0.97467 7.50079 2.19573 6.00696 5.17679 5.7405"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.99149 10.6578V1.46875"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8125 3.26949L8.99144 0.564453L6.17038 3.26949"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
              <p className="preview_documentfield">
                our_IBAN .PDF{" "}
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handledownload(list?.bank_iban)}
                >
                  <path
                    d="M12.7305 5.73242C15.7621 5.98274 17 7.47657 17 10.7468V10.8518C17 14.4612 15.4926 15.9066 11.7284 15.9066H6.24627C2.48205 15.9066 0.97467 14.4612 0.97467 10.8518V10.7468C0.97467 7.50079 2.19573 6.00696 5.17679 5.7405"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.99149 10.6578V1.46875"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8125 3.26949L8.99144 0.564453L6.17038 3.26949"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
              <p className="preview_documentfield">
                our_address.PDF{" "}
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handledownload(list?.national_address)}
                >
                  <path
                    d="M12.7305 5.73242C15.7621 5.98274 17 7.47657 17 10.7468V10.8518C17 14.4612 15.4926 15.9066 11.7284 15.9066H6.24627C2.48205 15.9066 0.97467 14.4612 0.97467 10.8518V10.7468C0.97467 7.50079 2.19573 6.00696 5.17679 5.7405"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.99149 10.6578V1.46875"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8125 3.26949L8.99144 0.564453L6.17038 3.26949"
                    stroke="#2C7873"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
            </div>
          </div>

          <div className="preview_form_titlediv">
            <h3 className="preview_form_title">Langugae</h3>
            <svg
              width="307"
              height="2"
              viewBox="0 0 397 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="preview_line"
            >
              <path d="M0 1L397 0.999965" stroke="#EDEDED" />
            </svg>
          </div>

          <div className="lang_subj_list">
            {list?.languages?.map((i: any) => (
              <p className="lang_subj_selected">{i.userlanguage.name}</p>
            ))}
          </div>
          <div className="preview_form_titlediv">
            <h3 className="preview_form_title">Subject</h3>
            <svg
              width="307"
              height="2"
              viewBox="0 0 397 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="preview_line"
            >
              <path d="M0 1L397 0.999965" stroke="#EDEDED" />
            </svg>
          </div>

          <div className="lang_subj_list">
            {list?.subject?.map((i: any) => (
              <p className="lang_subj_selected">{i.usersubject?.name}</p>
            ))}
          </div>
          {field.length === 0 ? (
            <button
              className="preview_accept"
              onClick={() =>
                handlestatus(list?.user_id, "Verify", [
                  {
                    Module_Name: "",
                    Module_Message: "",
                  },
                ])
              }
            >
              Accept Request
            </button>
          ) : (
            ""
          )}
          <div className="preview_btnlist">
            <button
              className="preview_decline"
              onClick={() => handlestatus(list?.user_id, "Decline", field)}
            >
              Decline
            </button>
            <button
              className="preview_declinemsg"
              onClick={() =>
                navigate("/DeclineWithMessage", {
                  state: {
                    id: list?.user_id,
                    field: field,
                  },
                })
              }
            >
              Decline With Message
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Agencypreview;
