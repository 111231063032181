import AuthLayout from "../Layout/AuthLayout";
import SignIn from "../component/Auth/SignIn";
import ForgotPassword from "../component/Auth/ForgetPassword";
// import OTPverify from "../component/Auth/OtpVerify";
// import ResetPassword from "../component/Auth/ResetPassword";

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  element: <AuthLayout />,
  children: [
    {
      path: "/",
      element: <SignIn />,
    },
    {
      path: "/ForgetPassword",
      element: <ForgotPassword />,
    },
    // {
    //   path: "/OTPverify",
    //   element: <OTPverify />,
    // },
    // {
    //   path: "/ResetPassword",
    //   element: <ResetPassword />,
    // },
  ],
};

export default LoginRoutes;
