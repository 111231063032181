import { Outlet } from "react-router-dom";
import ResponsiveDrawer from "./Sidebar";
import "./index.css";
import ProtectedRoute from "../../Protected Routes/ProtectedRoute";
import { ReactNode } from "react";
interface LayoutProps {
  children: ReactNode;
  headerTitle: ReactNode;
}

const MainLayout: React.FC<LayoutProps> = ({ headerTitle }) => {
  return (
    <ProtectedRoute>
      <div className="MainLayout">
        <ResponsiveDrawer />
        <div className="Mainbox">
          <div className="Mainleft">
            <div className="Mainheader">{headerTitle}</div>
            <div className="Mainbox">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default MainLayout;
