import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";

import "./agency.css";
import Header from "../../Layout/Header";
import projectimg from "../../assets/Projectimg.png";
import myprofile from "../../assets/myprofile.png";
import review1 from "../../assets/Review1.png";
import usa from "../../assets/usa.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { agencyreviewdetail } from "../../service/Mainservice";
// import {  } from "../../service/Mainservice";

const Agencydetails = () => {
  const [list, setlist] = useState<any>([]);
  const location = useLocation();

  useEffect(() => {
    getrequestlist(location.state.id);
  }, []);

  const getrequestlist = async (id: string) => {
    try {
      const response = await agencyreviewdetail(id);
      console.log(response);
      if (response.status === true) {
        setlist(response.data);
      } else {
        setlist([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">
          <svg
            width="17"
            height="16"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => navigate("/Agency")}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83331 9H20.1666"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Agency Details
        </h1>
        {/* <Header /> */}
      </div>
      <div className="Dashboard_screen">
        <div className="agency_main">
          <div className="agencymain_left">
            <div className="agency_imgdata">
              <img src={myprofile} alt="profile" />
              <div className="agency_agencyinfo">
                <div className="agency_agencyleft">
                  <h1 className="agency_name">{list.cr_name_english}</h1>
                  <div className="agency_numdivs">
                    <div className="agency_numinfo">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.9004 2.5886L15.8362 6.49216C16.1002 7.03556 16.8041 7.55677 17.398 7.65658L20.9066 8.24433C23.1504 8.62138 23.6783 10.2627 22.0615 11.8817L19.3338 14.632C18.8719 15.0978 18.6189 15.996 18.7619 16.6392L19.5428 20.0437C20.1587 22.7385 18.7399 23.781 16.3751 22.3726L13.0865 20.4097C12.4926 20.0548 11.5137 20.0548 10.9087 20.4097L7.62009 22.3726C5.26635 23.781 3.83651 22.7274 4.45244 20.0437L5.23336 16.6392C5.37634 15.996 5.12337 15.0978 4.66142 14.632L1.93372 11.8817C0.3279 10.2627 0.844843 8.62138 3.0886 8.24433L6.59721 7.65658C7.18014 7.55677 7.88406 7.03556 8.14804 6.49216L10.0838 2.5886C11.1397 0.470468 12.8555 0.470468 13.9004 2.5886Z"
                          stroke="#2C7873"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <div>
                        <p className="agency_ratetitle">Ratings</p>
                        <h2 className="agency_ratenum">
                          5.0 <span className="agency_ratetitle">out of 5</span>
                        </h2>
                      </div>
                    </div>
                    <hr />
                    <div className="agency_numinfo">
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.91821 19.2208L10.5155 22.0058C10.9796 22.4699 12.024 22.702 12.7203 22.702H17.1298C18.5223 22.702 20.0309 21.6576 20.379 20.2652L23.164 11.7942C23.7442 10.1696 22.6998 8.77709 20.9592 8.77709H16.3175C15.6213 8.77709 15.0411 8.19689 15.1571 7.3846L15.7373 3.67129C15.9694 2.62692 15.2732 1.46651 14.2288 1.11838C13.3005 0.770261 12.1401 1.23442 11.6759 1.93067L6.91821 9.00918"
                          stroke="#2C7873"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M1 19.2213V7.84931C1 6.22474 1.69625 5.64453 3.32082 5.64453H4.48123C6.10581 5.64453 6.80205 6.22474 6.80205 7.84931V19.2213C6.80205 20.8459 6.10581 21.4261 4.48123 21.4261H3.32082C1.69625 21.4261 1 20.8459 1 19.2213Z"
                          stroke="#2C7873"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div>
                        <p className="agency_ratetitle">Votes</p>
                        <h2 className="agency_ratenum">585.9K</h2>
                      </div>
                    </div>
                    <hr />
                    <div className="agency_numinfo">
                      <svg
                        width="22"
                        height="24"
                        viewBox="0 0 22 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.6667 19.5466H16.8222C15.9333 19.5466 15.0889 19.8878 14.4667 20.5042L12.5666 22.3644C11.7 23.2119 10.2889 23.2119 9.42224 22.3644L7.52222 20.5042C6.89999 19.8878 6.04444 19.5466 5.16667 19.5466H4.33333C2.48889 19.5466 1 18.0827 1 16.2776V4.26902C1 2.46388 2.48889 1 4.33333 1H17.6667C19.5111 1 21 2.46388 21 4.26902V16.2776C21 18.0717 19.5111 19.5466 17.6667 19.5466Z"
                          stroke="#2C7873"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11 9C12.1046 9 13 8.10455 13 6.99997C13 5.8954 12.1046 5 11 5C9.89544 5 9 5.8954 9 6.99997C9 8.10455 9.89544 9 11 9Z"
                          stroke="#2C7873"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 16C16 13.7914 13.7625 12 11 12C8.2375 12 6 13.7914 6 16"
                          stroke="#2C7873"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div>
                        <p className="agency_ratetitle">Followers</p>
                        <h2 className="agency_ratenum">157.22K </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Dashboard_titlediv">
              <h3>About Us</h3>
            </div>
            <p className="agency_aboutdata">
              Lorem ipsum dolor sit amet consectetur. Massa ut varius luctus
              ultrices tortor ut. Lacus mi vitae tristiqu eolestie Senectus
              libero diam donec hendrerit. Dictumst consectetur purus a
              tristique orci quam amet trum ut id vitae est tincidunt nunc donec
              pulvinar. Tempus blandit nunc velit sollicitudin feugat sagittis
              nunc estorec onsequat velit odio massa sit ut lacus tristique. Dui
              volutpat blandit vehicula ante porttitor vestibulum mac iaculis
              fames porta ornare sapien sed odio urna semper. Odio tempus id
              proinursus.
            </p>
            <div className="agency_numdivs2">
              <div>
                <h2 className="agency_ratenum2">$657,587</h2>
                <p className="agency_ratetitle">Revenue </p>
              </div>
              <hr />

              <div>
                <h2 className="agency_ratenum2">{list.cr_no}</h2>
                <p className="agency_ratetitle">CR Number </p>
              </div>
              <hr />

              <div>
                <h2 className="agency_ratenum2">24 Jan 2017</h2>
                <p className="agency_ratetitle">Joining Date </p>
              </div>
              <hr />

              <div>
                <h2 className="agency_ratenum2">{list.cr_expiry}</h2>
                <p className="agency_ratetitle">Expire Date </p>
              </div>
            </div>

            <div className="Dashboard_titlediv">
              <h3>Subject</h3>
            </div>
            <div className="lang_subj_list">
              {list.subject?.map((i: any) => (
                <p className="lang_subj_selected">{i.usersubject.name} </p>
              ))}
            </div>
            <div className="Dashboard_titlediv">
              <h3>Language</h3>
            </div>
            <div className="lang_subj_list">
              {list.languages?.map((i: any) => (
                <p className="lang_subj_selected">{i.userlanguage.name} </p>
              ))}
            </div>
            <div className="Dashboard_titlediv">
              <h3>Recents Projects</h3>
              <p>view all</p>
            </div>
            <div className="Agency_project">
              <img src={projectimg} alt="" />
              <img src={projectimg} alt="" />
              <img src={projectimg} alt="" />
              <img src={projectimg} alt="" />
            </div>

            <div className="Dashboard_titlediv">
              <h3>Recent Reviews</h3>
              <p>Most Resent</p>
            </div>
            <div className="profilepage_ratingdiv ">
              <img
                alt="imgs"
                src={review1}
                className="agency_reviewprofileimg"
              />
              <div>
                <h4 className="agency_reviews_name">Monalissa Hamlin</h4>
                <div className="agency_review_details">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Rating
                      name="read-only"
                      value={5}
                      size="small"
                      style={{ position: "initial", fontSize: "18px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Box>
                  |<p>2 days ago</p>|
                  <p className="agency_review_map">
                    <img src={usa} />
                    USA
                  </p>
                </div>
                <p className="agency_info">
                  Lorem ipsum dolor sit amet consectetur. Id interdum vulputate
                  nisl tortor. Eget mattis vitae sed eget. Justo turpis urna qu
                </p>
              </div>
            </div>
            <div className="profilepage_ratingdiv ">
              <img
                alt="imgs"
                src={review1}
                className="agency_reviewprofileimg"
              />
              <div>
                <h4 className="agency_reviews_name">Monalissa Hamlin</h4>
                <div className="agency_review_details">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Rating
                      name="read-only"
                      value={5}
                      size="small"
                      style={{ position: "initial", fontSize: "18px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Box>
                  |<p>2 days ago</p>|
                  <p className="agency_review_map">
                    <img src={usa} />
                    USA
                  </p>
                </div>
                <p className="agency_info">
                  Lorem ipsum dolor sit amet consectetur. Id interdum vulputate
                  nisl tortor. Eget mattis vitae sed eget. Justo turpis urna qu
                </p>
              </div>
            </div>
            <div className="profilepage_ratingdiv ">
              <img
                alt="imgs"
                src={review1}
                className="agency_reviewprofileimg"
              />
              <div>
                <h4 className="agency_reviews_name">Monalissa Hamlin</h4>
                <div className="agency_review_details">
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Rating
                      name="read-only"
                      value={5}
                      size="small"
                      style={{ position: "initial", fontSize: "18px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                  </Box>
                  |<p>2 days ago</p>|
                  <p className="agency_review_map">
                    <img src={usa} />
                    USA
                  </p>
                </div>
                <p className="agency_info">
                  Lorem ipsum dolor sit amet consectetur. Id interdum vulputate
                  nisl tortor. Eget mattis vitae sed eget. Justo turpis urna qu
                </p>
              </div>
            </div>
            <p className="agency_review_view">VIEW ALL REVIEWS</p>
          </div>
          <div className="agencymain_right">
            <div className="agency_allreview">
              <div className="Dashboard_titlediv">
                <h3>Reviews</h3>
              </div>
              <div className="agency_allreview_list">
                <div className="agency_allreview_details">
                  <h1>5.0</h1>
                  <p>15786 Reviews</p>
                </div>
                <div className="agency_review_stardetail">
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={5}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    1500
                  </p>
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={4}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    10
                  </p>
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={3}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    2
                  </p>
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={2}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    2
                  </p>
                  <p>
                    {" "}
                    <Rating
                      name="read-only"
                      value={1}
                      size="small"
                      style={{ position: "initial", fontSize: "16px" }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 1, color: "#C0BBAD" }}
                          fontSize="inherit"
                        />
                      }
                      readOnly
                    />
                    0
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Agencydetails;
