import { useRoutes } from "react-router-dom";

import AuthRoutes from "./AuthRoutes";
// import MainRoutesWithProtection from "./MainRoutes";
import MainRoutes from "./MainRoutes";
import Decline from "../component/Dashboard/Decline";

export default function Router() {
  return useRoutes([MainRoutes, AuthRoutes]);
}
