import { Link } from "react-router-dom";
import Timecount from "../Timecount/Timecount";
import Header from "../../Layout/Header";
import ticketprofile from "../../assets/ticketimg.png";
import noreport from "../../assets/noreports.png";
const Orderreport = () => {
  return (
    <>
      <div className="Dashboard_header">
        <h1 className="header_title">
          <svg
            width="17"
            height="16"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83331 9H20.1666"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          #58751784
        </h1>
        <Header />
      </div>
      <div className="Dashboard_main">
        <div className="Dashboard_left">
          <div className="Order_details_menu">
            <Link to="/Orderdetails" className="order_menuname ">
              Details
            </Link>
            <Link to="/" className="order_menuname">
              Requirement
            </Link>
            <Link to="/Orderdetails_Chat" className="order_menuname">
              Chats
            </Link>
            <Link
              to="/Orderdetails_Report"
              className="order_menuname active_menuname"
            >
              Reports / Tickets
            </Link>
          </div>
          <div className="orderdetails_noreport">
            <img src={noreport} alt="" />
            <p>Opps! there is no any active repost here!</p>
          </div>
          <div>
            <div className="Dashboard_titlediv">
              <h3>Solved Reports</h3>
              <p>view all</p>
            </div>
            <div className="Dashboard_transation_list">
              <div className="Dashboard_transation_user">
                <img src={ticketprofile} alt="" />
                <div className="Dashboard_transation_userdetail">
                  <h2 className="orderdetails_des">
                    I need to resolve my problem with this ongoing project.{" "}
                  </h2>
                  <div className="Dashboard_transation_datestatus">
                    <p className="orderdetails_ticket">#9585652</p>{" "}
                    <p>David Mc.Hamlin</p>
                    <p>21 February 2018 </p>
                  </div>
                </div>
              </div>
              <p className="orderdetails_solved">solved</p>
            </div>
            <div className="Dashboard_transation_list">
              <div className="Dashboard_transation_user">
                <img src={ticketprofile} alt="" />
                <div className="Dashboard_transation_userdetail">
                  <h2 className="orderdetails_des">
                    I need to resolve my problem with this ongoing project.{" "}
                  </h2>
                  <div className="Dashboard_transation_datestatus">
                    <p className="orderdetails_ticket">#9585652</p>{" "}
                    <p>David Mc.Hamlin</p>
                    <p>21 February 2018 </p>
                  </div>
                </div>
              </div>
              <p className="orderdetails_solved">Solved</p>
            </div>
            <div className="Dashboard_transation_list">
              <div className="Dashboard_transation_user">
                <img src={ticketprofile} alt="" />
                <div className="Dashboard_transation_userdetail">
                  <h2 className="orderdetails_des">
                    I need to resolve my problem with this ongoing project.{" "}
                  </h2>
                  <div className="Dashboard_transation_datestatus">
                    <p className="orderdetails_ticket">#9585652</p>{" "}
                    <p>David Mc.Hamlin</p>
                    <p>21 February 2018 </p>
                  </div>
                </div>
              </div>
              <p className="orderdetails_solved">Solved</p>
            </div>
          </div>
        </div>
        <div className="Dashboard_right">
          <Timecount />
        </div>
      </div>
    </>
  );
};
export default Orderreport;
