// import Pagination from "../Pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import profileimg from "../../assets/Review1.png";
import { ticketlist } from "../../service/Mainservice";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import "./Activeticket.css";
import Loader from "../../Loader/Loader";

const Activeticket = () => {
  const [loader, setloader] = useState(true);

  const [list, setlist] = useState<any>([]);
  const [totalpage, settotalpage] = useState(1);
  const [currentpage, setcurrentpage] = useState(1);
  const [totalitem, settotalitem] = useState(0);
  const [change, setchange] = useState<{ [key: number]: boolean }>({});
  const navigate = useNavigate();

  useEffect(() => {
    getrequestlist("1");
  }, []);

  const getrequestlist = async (page: string) => {
    setloader(true);
    try {
      const response = await ticketlist(page);
      console.log(response);
      setloader(false);

      if (response.status === true) {
        setlist(response.data);
        settotalitem(response.totalItems);
        settotalpage(response.totalPages);
        setcurrentpage(response.currentPage);
      } else {
        setlist([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const viewdetail = (id: string, index: number) => {
    setchange({
      ...change,
      [index]: !change[index],
    });
  };
  const startRange = (currentpage - 1) * 10 + 1;
  const endRange = Math.min(currentpage * 10, totalitem);

  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">Tickets</h1>
        {/* <Header /> */}
      </div>
      <div className="Dashboard_screen">
        <div className="Trasaction_main">
          <div className="Dashboard_overview">
            <div>
              <h4 className="Dashboard_overview_title">
                Active Tickets <span>({totalitem})</span>{" "}
              </h4>
              <p className="Transection_pagedatainfo">
                Showing results {startRange}-{endRange} of {totalitem}
              </p>
            </div>
            <div className="Transection_data">
              {/* <div className="Transection_search">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <circle
                      cx="8.01515"
                      cy="8.51515"
                      r="7.51515"
                      stroke="#0A0F0F"
                    />
                    <path
                      d="M13.6514 14.1514L15.9999 16.4999"
                      stroke="#0A0F0F"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>

                <input
                  type="search"
                  name="search"
                  id="search"
                  placeholder="Search here"
                />
              </div> */}

              <p className="Transection_data_filter">
                Date Range
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M1 1L6.5 6L12 1"
                    stroke="#7C7C7D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        {list ? (
          <div className="Transaction_table_data">
            <table className="Transaction_table">
              <thead>
                <tr>
                  <th className="transection_head_title">Name</th>
                  <th>Ticket Id</th>
                  <th>Date</th>
                  <th>Subjects</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((data: any, index: number) => {
                  console.log(new Date(Number(data.added_date)));
                  return (
                    <tr>
                      <td className="table_userinfo">
                        <img src={data.user_image} alt="" />
                        {data.user_name}{" "}
                      </td>
                      <td>#{data.ticket_no}</td>
                      <td>{moment(Number(data.added_date)).format("ll")}</td>
                      <td>{data.subcategory}</td>
                      <td>
                        {data.status === "Pending" ? (
                          <span className="pending_data">Pending</span>
                        ) : data.status === "Open" ? (
                          <span className="appactive_data">Active</span>
                        ) : data.status === "Closed" ? (
                          <span className="cancel_data">Closed</span>
                        ) : (
                          <span className="appactive_data">New</span>
                        )}
                      </td>
                      <td>
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate("/Ticketdetails", {
                              state: {
                                id: data.ticket_no,
                              },
                            })
                          }
                        >
                          <rect
                            opacity="0.05"
                            width="36"
                            height="36"
                            rx="18"
                            fill="#0A0F0F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 14.4395C24 15.7914 22.9034 16.8887 21.5507 16.8887C20.1988 16.8887 19.1028 15.7914 19.1028 14.4395C19.1028 13.0869 20.1988 11.9902 21.5507 11.9902C22.9034 11.9902 24 13.0869 24 14.4395Z"
                            stroke="#0A0F0F"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.8981 14.4395C16.8981 15.7914 15.8015 16.8887 14.4488 16.8887C13.0969 16.8887 12.0002 15.7914 12.0002 14.4395C12.0002 13.0869 13.0969 11.9902 14.4488 11.9902C15.8015 11.9902 16.8981 13.0869 16.8981 14.4395Z"
                            stroke="#0A0F0F"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 21.4981C24 22.8507 22.9034 23.9467 21.5507 23.9467C20.1988 23.9467 19.1028 22.8507 19.1028 21.4981C19.1028 20.1455 20.1988 19.0488 21.5507 19.0488C22.9034 19.0488 24 20.1455 24 21.4981Z"
                            stroke="#0A0F0F"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.8981 21.4981C16.8981 22.8507 15.8015 23.9467 14.4488 23.9467C13.0969 23.9467 12.0002 22.8507 12.0002 21.4981C12.0002 20.1455 13.0969 19.0488 14.4488 19.0488C15.8015 19.0488 16.8981 20.1455 16.8981 21.4981Z"
                            stroke="#0A0F0F"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              currentPage={currentpage}
              totalPages={totalpage}
              getorderlist={getrequestlist}
            />
          </div>
        ) : (
          <p className="nodata">No users</p>
        )}
      </div>
      {loader && <Loader />}
    </>
  );
};
export default Activeticket;
