import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import "./ActionMenu.css";
import { Modal } from "@mui/material";

interface MenuProps {
  showmenu: { [key: number]: boolean };
  setshowmenu: Dispatch<SetStateAction<{ [key: number]: boolean }>>;
  modalref: React.RefObject<HTMLDivElement>;
  index: number;
  id: string;
  modalPosition: any;
}

const Menu: React.FC<MenuProps> = ({
  showmenu,
  setshowmenu,
  modalref,
  index,
  id,
  modalPosition,
}) => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  const viewdetail = () => {
    setshowmenu({
      ...showmenu,
      [index]: !showmenu[index],
    });
    console.log(id);
    navigate("/AgencyRequest_preview", {
      state: {
        id: id,
      },
    });
  };

  return (
    <>
      <Modal
        open={showmenu[index]}
        onClose={() =>
          setshowmenu({
            ...showmenu,
            [index]: !showmenu[index],
          })
        }
        style={{
          backgroundColor: "rgb(0, 0, 0, 0.25)",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="actionmenumodal_content"
          ref={modalref}
          style={{
            top: modalPosition.top,
            left: modalPosition.left - 100,
          }}
        >
          <div className="actionmenu_drop" />
          <div className="actionmenu_detail" onClick={viewdetail}>
            <svg
              width="20"
              height="19"
              viewBox="0 0 23 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.1139 9.30204C15.1139 11.2872 13.504 12.8971 11.5188 12.8971C9.53373 12.8971 7.92383 11.2872 7.92383 9.30204C7.92383 7.3158 9.53373 5.70703 11.5188 5.70703C13.504 5.70703 15.1139 7.3158 15.1139 9.30204Z"
                  stroke="#7C7C7D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1 9.302C1 13.0312 5.70923 17.604 11.519 17.604C17.3276 17.604 22.038 13.0346 22.038 9.302C22.038 5.5694 17.3276 1 11.519 1C5.70923 1 1 5.57281 1 9.302Z"
                  stroke="#7C7C7D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <p>Full Preview</p>
          </div>
          <div
            className="actionmenu_detail"
            onClick={() => navigate("/DeclineWithMessage")}
          >
            <svg
              width="20"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <path
                  d="M11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21Z"
                  stroke="#7C7C7D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 7V12"
                  stroke="#7C7C7D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.9946 15H11.0036"
                  stroke="#7C7C7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <p>Decline with message</p>
          </div>
          <div className="actionmenu_btndivs">
            <button className="actionmenu_decline">Decline</button>
            <button className="actionmenu_accept">Accept</button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Menu;
