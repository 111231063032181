import React, { useEffect, useState } from "react";
import "./Pagination.css";

const Pagination = ({
  currentPage,
  totalPages,
  getorderlist,
}: {
  currentPage: number;
  totalPages: number;
  getorderlist: (page: string) => Promise<void>;
}) => {
  // const getPageRangeText = () => {
  //     const startRange = (pagedata.page - 1) * pagedata.per_page + 1;
  //     const endRange = Math.min(
  //       pagedata.page * pagedata.per_page,
  //       pagedata.total
  //     );

  //     return `${startRange}-${endRange} of ${pagedata.total}`;
  //   };
  const handleprevpage = () => {
    getorderlist(`${currentPage - 1}`);
  };
  const handlenextpage = () => {
    getorderlist(`${currentPage + 1}`);
  };

  return (
    <div className="pagedata">
      <button
        className="page"
        onClick={handleprevpage}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <button className="selectedpage">1</button>
      <button
        className="page"
        onClick={handlenextpage}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};
export default Pagination;
