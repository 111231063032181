import { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
// import dayjs from "dayjs";
import ReactApexChart from "react-apexcharts";
import Header from "../../Layout/Header";
import { useNavigate } from "react-router-dom";
// import { dashboard } from "../../service/Mainservice";
import chatprofile from "../../assets/chatprofile.png";
import ticketprofile from "../../assets/ticketimg.png";
import Menu from "./ActionMenu";
import { dashboard } from "../../service/Mainservice";
import Logout from "../../Layout/Logout";
import moment from "moment";

const Dashboard = () => {
  const [loading, setloading] = useState(true);
  const [selectedagencyid, setselectedagencyid] = useState("");
  const [details, setdetails] = useState<any>({});
  const navigate = useNavigate();
  const [modalPosition, setModalPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [menuopen, setmenuopen] = useState<{ [key: number]: boolean }>({});
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<SVGSVGElement>(null);

  const menuref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (menuref.current && !menuref.current.contains(event.target as Node)) {
        setmenuopen({});
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const data = await dashboard();
      console.log(data);
      setloading(false);
      setdetails(data.data);
    } catch (error) {
      setloading(false);
    }
  };
  const handleModalPosition = (
    e: React.MouseEvent<SVGSVGElement>,
    index: number
  ) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    setmenuopen({
      ...menuopen,
      [index]: !menuopen[index],
    });
    console.log(buttonRect);
    if (buttonRect.bottom > 535) {
      console.log("hello");
    }
    setModalPosition({
      top: buttonRect.bottom + 10,
      left: buttonRect.left - 60,
    });
  };

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "",
        data: [
          {
            x: "Jan",
            y: 40,
          },
          {
            x: "Feb",
            y: 60,
          },
          {
            x: "Mar",
            y: 80,
          },
          {
            x: "Apr",
            y: 20,
          },
          {
            x: "May",
            y: 40,
          },
          {
            x: "Jun",
            y: 10,
          },
          {
            x: "Jul",
            y: 60,
          },
          {
            x: "Aug",
            y: 40,
          },
          {
            x: "Sep",
            y: 20,
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 180,
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      fill: {
        colors: ["var(--appcolor)", "var(--textgrey)"],
      },
      xaxis: {
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        // stepSize: 20, here height is fixed so ui design effected
      },

      title: {
        text: "",
      },
    } as ApexCharts.ApexOptions,
  });
  const [chart2Data, setChart2Data] = useState({
    series: [
      {
        name: "series1",
        data: [4, 30, 4, 70.1, 10, 4, 50],
      },
    ],

    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["var(--appcolor)"],

      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0,
          opacityFrom: 0.1,
          opacityTo: 0,
          stops: [44, 120, 115],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
        ],
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return val + "k";
          },
        },
      },
    } as ApexCharts.ApexOptions,
  });

  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">Dashboard</h1>
        <Logout />
      </div>
      <div className="Dashboard_screen">
        <div className="Dashboard_main">
          <div className="Dashboard_left">
            <div className="Dashboard_card_list">
              <div className="Dashboard_card">
                <div className="Dashboard_card_detail">
                  <h3 className="Dashboard_card_value">
                    {details.TotalAgency}
                  </h3>
                  <p className="Dashboard_card_name">Total Agencies</p>
                </div>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M1 10.0008L9.2 1.80078"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 10V1H1"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div className="Dashboard_card">
                <div className="Dashboard_card_detail">
                  <h3 className="Dashboard_card_value">{details.TotalUsers}</h3>
                  <p className="Dashboard_card_name">Total Users</p>
                </div>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M1 10.0008L9.2 1.80078"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 10V1H1"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div className="Dashboard_card">
                <div className="Dashboard_card_detail">
                  <h3 className="Dashboard_card_value">
                    {details.TotalTicket}
                  </h3>
                  <p className="Dashboard_card_name">Total Ticket</p>
                </div>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M1 10.0008L9.2 1.80078"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 10V1H1"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div className="Dashboard_card">
                <div className="Dashboard_card_detail">
                  <h3 className="Dashboard_card_value">
                    {details.TotalTransaction}{" "}
                  </h3>
                  <p className="Dashboard_card_name">Total Transaction</p>
                </div>
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.4">
                    <path
                      d="M1 10.0008L9.2 1.80078"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 10V1H1"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="Dashboard_chart">
              <div className="Dashboard_order">
                <p className="Dashboard_order_name active_order_name">
                  Total Revenue
                </p>
                <p className="Dashboard_order_name">Average Revenue</p>
              </div>
              <h2 className="Dashboard_ordernum">$657,587 USD</h2>
              <div className="Dashboard_overview">
                <h4 className="Dashboard_overview_title">Overview</h4>
                <div className="Dashboard_orderdata">
                  <select className="Dashboard_select">
                    <option value="Total Orders">Total Revenue</option>
                    <option value="Total Orders">Total Orders</option>
                  </select>
                  <select className="Dashboard_select">
                    <option value="Total Orders">Monthly</option>
                  </select>

                  {/* <p>
                    Total Order{" "}
                    <svg
                      width="9"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6.5 6L12 1"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p> */}
                </div>
              </div>
              <div id="chart">
                <ReactApexChart
                  options={chart2Data.options}
                  series={chart2Data.series}
                  type="area"
                  height={218}
                />
              </div>
            </div>
            {/* <div className="Dashboard_chart">
                  <p className="Dashboard_order">Total Orders</p>
                  <h2 className="Dashboard_ordernum">16</h2>
                  <div className="Dashboard_overview">
                    <h4 className="Dashboard_overview_title">Overview</h4>
                    <div className="Dashboard_orderdata">
                      <p>
                        Total Order{" "}
                        <svg
                          width="9"
                          height="7"
                          viewBox="0 0 13 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M1 1L6.5 6L12 1"
                            stroke="#7C7C7D"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>
                        Monthly{" "}
                        <svg
                          width="9"
                          height="7"
                          viewBox="0 0 13 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M1 1L6.5 6L12 1"
                            stroke="#7C7C7D"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                    </div>
                  </div>
                  <div id="chart">
                    <ReactApexChart
                      options={chart2Data.options}
                      series={chart2Data.series}
                      type="area"
                      height={250}
                    />
                  </div>
              </div> */}

            <div className="Dashboard_Orders">
              <div className="Dashboard_titlediv">
                <h3>
                  Recent Requests <span> (150+)</span>
                </h3>
                <p onClick={() => navigate("/Requests")}>view all</p>
              </div>
              <div className="Transaction_table_data">
                <table className="Transaction_table">
                  <thead>
                    <tr>
                      <th className="transection_head_title">Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details.Pending_Agency_Request?.map(
                      (data: any, index: number) => (
                        <tr>
                          <td className="table_userinfo">
                            <img src={data.user_image} alt="" />
                            {data.agency_detail.fullname}
                          </td>
                          <td>{data.email}</td>
                          <td>
                            {" "}
                            <span className="pending_data">
                              Pending
                              {/* {data.agency_detail.status} */}
                            </span>
                            {/*  how to status color change 
                                        {item.order_status === 1 ? (
                                        <span className="pending_data">Pending</span>
                                      ) : item.order_status === 2 ? (
                                        <span className="upcoming_data">Upcoming</span>
                                      ) : item.order_status === 3 ? (
                                        <span className="start_data">Trip Started</span>
                                      ) : item.order_status === 4 ? (
                                        <span className="cancel_data">Canceled</span>
                                      ) : (
                                        <span className="complete_data">Completed</span>
                                      )} */}
                          </td>
                          <td className="action_menu">
                            <svg
                              width="36"
                              height="36"
                              viewBox="0 0 36 36"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              ref={buttonRef}
                              onClick={(e) => handleModalPosition(e, index)}
                              style={{ cursor: "pointer" }}
                            >
                              <rect
                                opacity="0.05"
                                width="36"
                                height="36"
                                rx="18"
                                fill="#0A0F0F"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24 14.4395C24 15.7914 22.9034 16.8887 21.5507 16.8887C20.1988 16.8887 19.1028 15.7914 19.1028 14.4395C19.1028 13.0869 20.1988 11.9902 21.5507 11.9902C22.9034 11.9902 24 13.0869 24 14.4395Z"
                                stroke="#0A0F0F"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.8981 14.4395C16.8981 15.7914 15.8015 16.8887 14.4488 16.8887C13.0969 16.8887 12.0002 15.7914 12.0002 14.4395C12.0002 13.0869 13.0969 11.9902 14.4488 11.9902C15.8015 11.9902 16.8981 13.0869 16.8981 14.4395Z"
                                stroke="#0A0F0F"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24 21.4981C24 22.8507 22.9034 23.9467 21.5507 23.9467C20.1988 23.9467 19.1028 22.8507 19.1028 21.4981C19.1028 20.1455 20.1988 19.0488 21.5507 19.0488C22.9034 19.0488 24 20.1455 24 21.4981Z"
                                stroke="#0A0F0F"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.8981 21.4981C16.8981 22.8507 15.8015 23.9467 14.4488 23.9467C13.0969 23.9467 12.0002 22.8507 12.0002 21.4981C12.0002 20.1455 13.0969 19.0488 14.4488 19.0488C15.8015 19.0488 16.8981 20.1455 16.8981 21.4981Z"
                                stroke="#0A0F0F"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            {menuopen[index] && (
                              <Menu
                                showmenu={menuopen}
                                setshowmenu={setmenuopen}
                                modalref={modalRef}
                                id={data.id}
                                index={index}
                                modalPosition={modalPosition}
                              />
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="Dashboard_right">
            <div className="Dashboard_Divs">
              <div className="Dashboard_titlediv">
                <h3>Active Tickets</h3>
                <p>view all</p>
              </div>
              {details?.Ticket?.splice(0, 5).map((i: any) => (
                <div className="Dashboard_transation_list">
                  <div className="Dashboard_transation_user">
                    <img src={ticketprofile} alt="" />
                    <div className="Dashboard_transation_userdetail">
                      <h2 className="Dashboard_transation_username">
                        {i.user_name}
                      </h2>
                      <div className="Dashboard_transation_datestatus">
                        <p>{moment(Number(i.added_date)).format("ll")}</p>
                        <p>
                          {i.status === "Open"
                            ? "Active"
                            : i.status === "Pending"
                            ? "Pending"
                            : "Closed"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="Dashboard_transation_money">#{i.ticket_no}</p>
                </div>
              ))}
            </div>

            {/* <div className="Dashboard_Divs" style={{ border: "None" }}>
              <div className="Dashboard_titlediv">
                <h3>Recent Chat</h3>
                <p>view all</p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Hello! hope you are fine...</p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_chat_date">Today, 10:00 PM</p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Hello! hope you are fine...</p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_chat_date">Today, 10:00 PM</p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Hello! hope you are fine...</p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_chat_date">Today, 10:00 PM</p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Hello! hope you are fine...</p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_chat_date">Today, 10:00 PM</p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Hello! hope you are fine...</p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_chat_date">Today, 10:00 PM</p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Hello! hope you are fine...</p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_chat_date">Today, 10:00 PM</p>
              </div>
            </div>*/}
          </div>{" "}
        </div>
      </div>
    </>
  );
};
export default Dashboard;
