import { useLocation, useNavigate } from "react-router-dom";
import PauseIcon from "@mui/icons-material/Pause";
import { useEffect, useState } from "react";
import { orderdetails } from "../../service/Mainservice";
import Logout from "../../Layout/Logout";
import moment from "moment";
import { Modal } from "@mui/material";
import Loader from "../../Loader/Loader";

const OrderDetails = () => {
  const [loader, setloader] = useState(true);
  const [list, setlist] = useState<any>([]);
  const [play, setplay] = useState(false);
  const [load, setload] = useState(false);
  const [cancelmodal, setcancelmodal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getrequestlist(location.state.num);
  }, []);

  const getrequestlist = async (num: string) => {
    try {
      const response = await orderdetails(num);
      setloader(false);
      if (response.status === true) {
        setlist(response.data[0]);
      } else {
        setlist([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handledownload = (response: string) => {
    // const blob = new Blob([response], {
    //   type: "application/pdf",
    // });
    // Create a temporary URL for the Blob
    // const url = URL.createObjectURL(blob);
    //  Create an anchor element
    const a = document.createElement("a");

    // Set the anchor's href to the Blob URL and specify the filename for download
    a.href = response;
    a.target = "_blank";
    // a.download = "Project Details.pdf";

    // Simulate a click on the anchor element to trigger the download
    a.click();

    // Clean up by revoking the Blob URL
    // URL.revokeObjectURL(url);
  };

  const [activedeadlinedate, timeWithTimezoneAD] = moment(
    Number(list.order_deadline)
  )
    .format()
    .split("T");

  // const activedeadlinetime = timeWithTimezoneAD.split("+")[0].split("-")[0];
  const [activesubmissiondate, timeWithTimezoneAS] = moment(
    Number(list.order_complete_timestamp)
  )
    .format()
    .split("T");

  // const activesubmissiontime = timeWithTimezoneAS.split("+")[0].split("-")[0];

  const late = list.order_complete_timestamp
    ? new Date(Number(list.order_complete_timestamp)).getTime() -
      new Date(Number(list.order_deadline)).getTime()
    : 0;

  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">
          <svg
            width="17"
            height="16"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => navigate("/Orders")}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83331 9H20.1666"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {list && <span>#{list.order_no}</span>}
        </h1>
        <Logout />
      </div>
      <div className="Dashboard_screen">
        <div className="Dashboard_main">
          <div className="Dashboard_left">
            <div className="Dashboard_titlediv">
              <h3>Order Status</h3>
            </div>

            <table className="preview_history_table">
              <tr>
                <th>Date</th>
                <th>Status</th>
              </tr>
              {list?.allstatus?.map((i: any) => {
                return (
                  <tr>
                    <td>
                      {moment(Number(i.Date)).format().split("T")[0]}{" "}
                      {
                        moment(Number(i.Date))
                          .format()
                          .split("T")[1]
                          .split("+")[0]
                          .split("-")[0]
                      }
                    </td>
                    <td>{i.Status}</td>
                  </tr>
                );
              })}
            </table>

            {/* <div className="Order_costdata">
              <div>
                <p className="order_costtitle">Project Cost</p>
                <h3 className="order_costnum"><span>{list.price}SAR</h3>
              </div>
              <div>
                <p className="order_costtitle">Translation</p>
                <h3 className="order_costnum">
                  <span>{list.from_language} to <span>{list.to_language}{" "}
                </h3>
              </div>
              <div>
                <p className="order_costtitle">Subject</p>
                <h3 className="order_costnum"><span>{list.subject}</h3>
              </div>
            </div> */}
            <div className="order_details_data">
              <p>
                Order ID: <span>{list.order_no}</span>
              </p>
              <p>
                Translator: <span>{list.agency_user_name}</span>
              </p>
              <p>
                Client: <span>{list.customer_user_name}</span>
              </p>
              <p>
                Langugae:{" "}
                <span>
                  {list.from_language} to {list.to_language}
                </span>
              </p>
              <p>
                Subject: <span>{list.subject}</span>
              </p>
              <p>
                Price: <span>{list.price}</span>
              </p>
              <p>
                Currency: <span>SAR</span>
              </p>
              <p>
                Date:{" "}
                <span>
                  {" "}
                  {list.order_activateat === ""
                    ? "--"
                    : moment(Number(list.order_activateat))
                        .format()
                        .split("T")[0]}
                </span>
              </p>
              <p>
                Active_deadline:{" "}
                <span>{list.order_deadline ? activedeadlinedate : "--"}</span>
              </p>
              <p>
                Active_submission:{" "}
                <span>
                  {list.order_complete_timestamp === ""
                    ? "--"
                    : moment(Number(list.order_complete_timestamp))
                        .format()
                        .split("T")[0]}
                </span>
              </p>
              <p>
                Revision1_start:{" "}
                <span>
                  {list.order_revision1_start_timestamp === ""
                    ? "--"
                    : moment(Number(list.order_revision1_start_timestamp))
                        .format()
                        .split("T")[0]}
                </span>
              </p>
              <p>
                Revision1_submission:{" "}
                <span>
                  {list.order_revision1_complete_timestamp === ""
                    ? "--"
                    : moment(Number(list.order_revision1_complete_timestamp))
                        .format()
                        .split("T")[0]}
                </span>
              </p>
              <p>
                Revision2_start:{" "}
                <span>
                  {list.order_revision2_start_timestamp === ""
                    ? "--"
                    : moment(Number(list.order_revision2_start_timestamp))
                        .format()
                        .split("T")[0]}
                </span>
              </p>
              <p>
                Revision2_submission:{" "}
                <span>
                  {list.order_revision2_complete_timestamp === ""
                    ? "--"
                    : moment(Number(list.order_revision2_complete_timestamp))
                        .format()
                        .split("T")[0]}
                </span>
              </p>
              <p>
                Late: <span>{late > 0 ? "Yes" : "No"}</span>
              </p>
              <p>
                Status:{" "}
                {list.status === 1 ? (
                  <span className="appactive_data">New</span>
                ) : list.status === 2 ? (
                  <span className="pending_data">Pending</span>
                ) : list.status === 3 ? (
                  <span className="active_data">Active</span>
                ) : list.status === 4 ? (
                  <span className="cancel_data">Canceled</span>
                ) : list.status === 5 ? (
                  <span className="complete_data">Completed</span>
                ) : list.status === 6 ? (
                  <span className="active_data">Revision1</span>
                ) : (
                  <span className="active_data">Revision2</span>
                )}
              </p>
              <p>
                Description: <span>{list.order_desc}</span>
              </p>
              <p>
                Voice Note:{" "}
                {play ? (
                  <PauseIcon onClick={() => setplay(false)} />
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setplay(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <g clip-path="url(#clip0_2_4110)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 9.9235L0 20V0L20 9.9235Z"
                        fill="#2C7873"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2_4110">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </p>
              <p>
                Original_document:{" "}
                <span>
                  {list.order_document ? (
                    <svg
                      width="37"
                      height="39"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handledownload(list.order_document)}
                    >
                      <g
                        clip-path="url(#clip0_232_22556)"
                        filter="url(#filter0_d_232_22556)"
                      >
                        <path
                          d="M12.0265 15.8838C12.0265 15.049 11.4468 14.5508 10.4239 14.5508C10.0066 14.5508 9.72393 14.591 9.57568 14.6314V17.3119C9.75094 17.3517 9.96639 17.3653 10.2619 17.3653C11.3532 17.3653 12.0265 16.8132 12.0265 15.8838Z"
                          fill="#2C7873"
                        />
                        <path
                          d="M18.3579 14.5781C17.8995 14.5781 17.6039 14.6185 17.4282 14.6589V20.5979C17.6036 20.6389 17.8865 20.6389 18.1424 20.6389C20.0007 20.6519 21.2128 19.6289 21.2128 17.4605C21.2264 15.5743 20.1218 14.5781 18.3579 14.5781Z"
                          fill="#2C7873"
                        />
                        <path
                          d="M30.4893 11.0886H29.7449V7.49689C29.7449 7.47449 29.7414 7.45192 29.7384 7.42924C29.7372 7.28662 29.6918 7.14653 29.5942 7.03555L23.6172 0.207904C23.6154 0.20616 23.6137 0.205541 23.6125 0.203627C23.5768 0.163836 23.5353 0.130742 23.4914 0.102095C23.4784 0.0933713 23.4653 0.0860547 23.4517 0.0785129C23.4136 0.057745 23.3733 0.0405228 23.3317 0.0280846C23.3205 0.0249328 23.3103 0.0203177 23.2991 0.017391C23.2539 0.00669752 23.2071 0 23.1596 0H8.47043C7.79966 0 7.25475 0.545538 7.25475 1.21574V11.0883H6.51053C5.55099 11.0883 4.77295 11.866 4.77295 12.8259V21.8616C4.77295 22.8208 5.55099 23.599 6.51053 23.599H7.2548V29.7843C7.2548 30.4544 7.79972 31 8.47049 31H28.5293C29.1994 31 29.745 30.4545 29.745 29.7843V23.599H30.4893C31.4486 23.599 32.2268 22.8208 32.2268 21.8616V12.8261C32.2268 11.8663 31.4485 11.0886 30.4893 11.0886ZM8.47043 1.21597H22.5517V7.43582C22.5517 7.77165 22.8242 8.04366 23.1596 8.04366H28.5292V11.089H8.47043V1.21597ZM23.394 17.3923C23.394 19.1301 22.7612 20.3283 21.8845 21.0694C20.9294 21.8639 19.4746 22.2408 17.6974 22.2408C16.6337 22.2408 15.8797 22.1735 15.3673 22.1064V13.1905C16.1214 13.0695 17.1052 13.0021 18.1422 13.0021C19.8651 13.0021 20.9832 13.3121 21.859 13.9716C22.8013 14.6721 23.394 15.7897 23.394 17.3923ZM7.54178 22.1468V13.1905C8.17444 13.0833 9.06375 13.0021 10.3162 13.0021C11.5821 13.0021 12.4846 13.2446 13.0904 13.7297C13.6697 14.1874 14.0593 14.9417 14.0593 15.8306C14.0593 16.7198 13.7638 17.4739 13.2247 17.9854C12.5245 18.6454 11.4876 18.9419 10.2752 18.9419C10.0062 18.9419 9.763 18.9283 9.5753 18.9012V22.147H7.54178V22.1468ZM28.5293 29.4552H8.47043V23.599H28.5292V29.4552H28.5293ZM30.2907 14.7529H26.8019V16.827H30.0613V18.4971H26.8019V22.1468H24.7424V13.0695H30.2907V14.7529Z"
                          fill="#2C7873"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_232_22556"
                          x="-1"
                          y="0"
                          width="39"
                          height="39"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_232_22556"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_232_22556"
                            result="shape"
                          />
                        </filter>
                        <clipPath id="clip0_232_22556">
                          <rect
                            width="31"
                            height="31"
                            fill="white"
                            transform="translate(3)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    ""
                  )}
                </span>
              </p>
              <p>
                Translated_document:{" "}
                <span>
                  {list.translate_file ? (
                    <svg
                      width="37"
                      height="39"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handledownload(list.translate_file)}
                    >
                      <g
                        clip-path="url(#clip0_232_22556)"
                        filter="url(#filter0_d_232_22556)"
                      >
                        <path
                          d="M12.0265 15.8838C12.0265 15.049 11.4468 14.5508 10.4239 14.5508C10.0066 14.5508 9.72393 14.591 9.57568 14.6314V17.3119C9.75094 17.3517 9.96639 17.3653 10.2619 17.3653C11.3532 17.3653 12.0265 16.8132 12.0265 15.8838Z"
                          fill="#2C7873"
                        />
                        <path
                          d="M18.3579 14.5781C17.8995 14.5781 17.6039 14.6185 17.4282 14.6589V20.5979C17.6036 20.6389 17.8865 20.6389 18.1424 20.6389C20.0007 20.6519 21.2128 19.6289 21.2128 17.4605C21.2264 15.5743 20.1218 14.5781 18.3579 14.5781Z"
                          fill="#2C7873"
                        />
                        <path
                          d="M30.4893 11.0886H29.7449V7.49689C29.7449 7.47449 29.7414 7.45192 29.7384 7.42924C29.7372 7.28662 29.6918 7.14653 29.5942 7.03555L23.6172 0.207904C23.6154 0.20616 23.6137 0.205541 23.6125 0.203627C23.5768 0.163836 23.5353 0.130742 23.4914 0.102095C23.4784 0.0933713 23.4653 0.0860547 23.4517 0.0785129C23.4136 0.057745 23.3733 0.0405228 23.3317 0.0280846C23.3205 0.0249328 23.3103 0.0203177 23.2991 0.017391C23.2539 0.00669752 23.2071 0 23.1596 0H8.47043C7.79966 0 7.25475 0.545538 7.25475 1.21574V11.0883H6.51053C5.55099 11.0883 4.77295 11.866 4.77295 12.8259V21.8616C4.77295 22.8208 5.55099 23.599 6.51053 23.599H7.2548V29.7843C7.2548 30.4544 7.79972 31 8.47049 31H28.5293C29.1994 31 29.745 30.4545 29.745 29.7843V23.599H30.4893C31.4486 23.599 32.2268 22.8208 32.2268 21.8616V12.8261C32.2268 11.8663 31.4485 11.0886 30.4893 11.0886ZM8.47043 1.21597H22.5517V7.43582C22.5517 7.77165 22.8242 8.04366 23.1596 8.04366H28.5292V11.089H8.47043V1.21597ZM23.394 17.3923C23.394 19.1301 22.7612 20.3283 21.8845 21.0694C20.9294 21.8639 19.4746 22.2408 17.6974 22.2408C16.6337 22.2408 15.8797 22.1735 15.3673 22.1064V13.1905C16.1214 13.0695 17.1052 13.0021 18.1422 13.0021C19.8651 13.0021 20.9832 13.3121 21.859 13.9716C22.8013 14.6721 23.394 15.7897 23.394 17.3923ZM7.54178 22.1468V13.1905C8.17444 13.0833 9.06375 13.0021 10.3162 13.0021C11.5821 13.0021 12.4846 13.2446 13.0904 13.7297C13.6697 14.1874 14.0593 14.9417 14.0593 15.8306C14.0593 16.7198 13.7638 17.4739 13.2247 17.9854C12.5245 18.6454 11.4876 18.9419 10.2752 18.9419C10.0062 18.9419 9.763 18.9283 9.5753 18.9012V22.147H7.54178V22.1468ZM28.5293 29.4552H8.47043V23.599H28.5292V29.4552H28.5293ZM30.2907 14.7529H26.8019V16.827H30.0613V18.4971H26.8019V22.1468H24.7424V13.0695H30.2907V14.7529Z"
                          fill="#2C7873"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_232_22556"
                          x="-1"
                          y="0"
                          width="39"
                          height="39"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_232_22556"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_232_22556"
                            result="shape"
                          />
                        </filter>
                        <clipPath id="clip0_232_22556">
                          <rect
                            width="31"
                            height="31"
                            fill="white"
                            transform="translate(3)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    "--"
                  )}
                </span>
              </p>
              <p>
                Revision1_document:{" "}
                <span>
                  {list.order_translatefile_revisionfile_1 ? (
                    <svg
                      width="37"
                      height="39"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() =>
                        handledownload(list.order_translatefile_revisionfile_1)
                      }
                    >
                      <g
                        clip-path="url(#clip0_232_22556)"
                        filter="url(#filter0_d_232_22556)"
                      >
                        <path
                          d="M12.0265 15.8838C12.0265 15.049 11.4468 14.5508 10.4239 14.5508C10.0066 14.5508 9.72393 14.591 9.57568 14.6314V17.3119C9.75094 17.3517 9.96639 17.3653 10.2619 17.3653C11.3532 17.3653 12.0265 16.8132 12.0265 15.8838Z"
                          fill="#2C7873"
                        />
                        <path
                          d="M18.3579 14.5781C17.8995 14.5781 17.6039 14.6185 17.4282 14.6589V20.5979C17.6036 20.6389 17.8865 20.6389 18.1424 20.6389C20.0007 20.6519 21.2128 19.6289 21.2128 17.4605C21.2264 15.5743 20.1218 14.5781 18.3579 14.5781Z"
                          fill="#2C7873"
                        />
                        <path
                          d="M30.4893 11.0886H29.7449V7.49689C29.7449 7.47449 29.7414 7.45192 29.7384 7.42924C29.7372 7.28662 29.6918 7.14653 29.5942 7.03555L23.6172 0.207904C23.6154 0.20616 23.6137 0.205541 23.6125 0.203627C23.5768 0.163836 23.5353 0.130742 23.4914 0.102095C23.4784 0.0933713 23.4653 0.0860547 23.4517 0.0785129C23.4136 0.057745 23.3733 0.0405228 23.3317 0.0280846C23.3205 0.0249328 23.3103 0.0203177 23.2991 0.017391C23.2539 0.00669752 23.2071 0 23.1596 0H8.47043C7.79966 0 7.25475 0.545538 7.25475 1.21574V11.0883H6.51053C5.55099 11.0883 4.77295 11.866 4.77295 12.8259V21.8616C4.77295 22.8208 5.55099 23.599 6.51053 23.599H7.2548V29.7843C7.2548 30.4544 7.79972 31 8.47049 31H28.5293C29.1994 31 29.745 30.4545 29.745 29.7843V23.599H30.4893C31.4486 23.599 32.2268 22.8208 32.2268 21.8616V12.8261C32.2268 11.8663 31.4485 11.0886 30.4893 11.0886ZM8.47043 1.21597H22.5517V7.43582C22.5517 7.77165 22.8242 8.04366 23.1596 8.04366H28.5292V11.089H8.47043V1.21597ZM23.394 17.3923C23.394 19.1301 22.7612 20.3283 21.8845 21.0694C20.9294 21.8639 19.4746 22.2408 17.6974 22.2408C16.6337 22.2408 15.8797 22.1735 15.3673 22.1064V13.1905C16.1214 13.0695 17.1052 13.0021 18.1422 13.0021C19.8651 13.0021 20.9832 13.3121 21.859 13.9716C22.8013 14.6721 23.394 15.7897 23.394 17.3923ZM7.54178 22.1468V13.1905C8.17444 13.0833 9.06375 13.0021 10.3162 13.0021C11.5821 13.0021 12.4846 13.2446 13.0904 13.7297C13.6697 14.1874 14.0593 14.9417 14.0593 15.8306C14.0593 16.7198 13.7638 17.4739 13.2247 17.9854C12.5245 18.6454 11.4876 18.9419 10.2752 18.9419C10.0062 18.9419 9.763 18.9283 9.5753 18.9012V22.147H7.54178V22.1468ZM28.5293 29.4552H8.47043V23.599H28.5292V29.4552H28.5293ZM30.2907 14.7529H26.8019V16.827H30.0613V18.4971H26.8019V22.1468H24.7424V13.0695H30.2907V14.7529Z"
                          fill="#2C7873"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_232_22556"
                          x="-1"
                          y="0"
                          width="39"
                          height="39"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_232_22556"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_232_22556"
                            result="shape"
                          />
                        </filter>
                        <clipPath id="clip0_232_22556">
                          <rect
                            width="31"
                            height="31"
                            fill="white"
                            transform="translate(3)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    "--"
                  )}
                </span>
              </p>
              <p>
                Revision2_document:{" "}
                <span>
                  {list.order_translatefile_revisionfile_2 ? (
                    <svg
                      width="37"
                      height="39"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() =>
                        handledownload(list.order_translatefile_revisionfile_2)
                      }
                    >
                      <g
                        clip-path="url(#clip0_232_22556)"
                        filter="url(#filter0_d_232_22556)"
                      >
                        <path
                          d="M12.0265 15.8838C12.0265 15.049 11.4468 14.5508 10.4239 14.5508C10.0066 14.5508 9.72393 14.591 9.57568 14.6314V17.3119C9.75094 17.3517 9.96639 17.3653 10.2619 17.3653C11.3532 17.3653 12.0265 16.8132 12.0265 15.8838Z"
                          fill="#2C7873"
                        />
                        <path
                          d="M18.3579 14.5781C17.8995 14.5781 17.6039 14.6185 17.4282 14.6589V20.5979C17.6036 20.6389 17.8865 20.6389 18.1424 20.6389C20.0007 20.6519 21.2128 19.6289 21.2128 17.4605C21.2264 15.5743 20.1218 14.5781 18.3579 14.5781Z"
                          fill="#2C7873"
                        />
                        <path
                          d="M30.4893 11.0886H29.7449V7.49689C29.7449 7.47449 29.7414 7.45192 29.7384 7.42924C29.7372 7.28662 29.6918 7.14653 29.5942 7.03555L23.6172 0.207904C23.6154 0.20616 23.6137 0.205541 23.6125 0.203627C23.5768 0.163836 23.5353 0.130742 23.4914 0.102095C23.4784 0.0933713 23.4653 0.0860547 23.4517 0.0785129C23.4136 0.057745 23.3733 0.0405228 23.3317 0.0280846C23.3205 0.0249328 23.3103 0.0203177 23.2991 0.017391C23.2539 0.00669752 23.2071 0 23.1596 0H8.47043C7.79966 0 7.25475 0.545538 7.25475 1.21574V11.0883H6.51053C5.55099 11.0883 4.77295 11.866 4.77295 12.8259V21.8616C4.77295 22.8208 5.55099 23.599 6.51053 23.599H7.2548V29.7843C7.2548 30.4544 7.79972 31 8.47049 31H28.5293C29.1994 31 29.745 30.4545 29.745 29.7843V23.599H30.4893C31.4486 23.599 32.2268 22.8208 32.2268 21.8616V12.8261C32.2268 11.8663 31.4485 11.0886 30.4893 11.0886ZM8.47043 1.21597H22.5517V7.43582C22.5517 7.77165 22.8242 8.04366 23.1596 8.04366H28.5292V11.089H8.47043V1.21597ZM23.394 17.3923C23.394 19.1301 22.7612 20.3283 21.8845 21.0694C20.9294 21.8639 19.4746 22.2408 17.6974 22.2408C16.6337 22.2408 15.8797 22.1735 15.3673 22.1064V13.1905C16.1214 13.0695 17.1052 13.0021 18.1422 13.0021C19.8651 13.0021 20.9832 13.3121 21.859 13.9716C22.8013 14.6721 23.394 15.7897 23.394 17.3923ZM7.54178 22.1468V13.1905C8.17444 13.0833 9.06375 13.0021 10.3162 13.0021C11.5821 13.0021 12.4846 13.2446 13.0904 13.7297C13.6697 14.1874 14.0593 14.9417 14.0593 15.8306C14.0593 16.7198 13.7638 17.4739 13.2247 17.9854C12.5245 18.6454 11.4876 18.9419 10.2752 18.9419C10.0062 18.9419 9.763 18.9283 9.5753 18.9012V22.147H7.54178V22.1468ZM28.5293 29.4552H8.47043V23.599H28.5292V29.4552H28.5293ZM30.2907 14.7529H26.8019V16.827H30.0613V18.4971H26.8019V22.1468H24.7424V13.0695H30.2907V14.7529Z"
                          fill="#2C7873"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_232_22556"
                          x="-1"
                          y="0"
                          width="39"
                          height="39"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_232_22556"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_232_22556"
                            result="shape"
                          />
                        </filter>
                        <clipPath id="clip0_232_22556">
                          <rect
                            width="31"
                            height="31"
                            fill="white"
                            transform="translate(3)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    "--"
                  )}
                </span>
              </p>
            </div>

            {play ? (
              <audio
                src={list.order_voice_note}
                autoPlay
                onEnded={() => setplay(false)}
              />
            ) : (
              ""
            )}
            <button
              className="cancelorder_button"
              onClick={() => setcancelmodal(true)}
            >
              Cancel order
            </button>
          </div>
          <div className="Dashboard_right">
            {/* <div className="Dashboard_titlediv">
              <h3>Tickets List</h3>
            </div>

            {list?.allstatus?<table className="preview_history_table">
              <tr>
                <th>Ticket</th>
                <th>Status</th>
              </tr>
              {list?.allstatus?.map((i: any) => {
                return (
                  <tr>
                    <td>534530298 </td>
                    <td>{i.Status}</td>
                  </tr>
                );
              })}
            </table>:
            } */}

            {/* <Timecount data={list} /> */}
            {cancelmodal && (
              <Modal
                open={cancelmodal}
                onClose={() => setcancelmodal(false)}
                style={{
                  backgroundColor: "rgb(0, 0, 0, 0.25)",
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="confirm_accept_modal">
                  <h3 className="confirm_accept_title">
                    Confirm accepting order:{" "}
                  </h3>
                  <p className="confirm_accept_data">
                    order id:{""}
                    <span>#{list.order_no}</span>
                  </p>
                  <div className="confirm_accept_btnlist">
                    <button
                      className="confirm_accept_button"
                      onClick={() => setcancelmodal(false)}
                      disabled={load}
                    >
                      {load ? "Cancelling..." : "Cancel"}
                    </button>
                    <button
                      className="confirm_abort_button"
                      onClick={() => setcancelmodal(false)}
                    >
                      Abort
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};
export default OrderDetails;
