import * as React from "react";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

// import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
// import PostAddIcon from "@mui/icons-material/PostAdd";
// import CallIcon from "@mui/icons-material/Call";
// import IconButton from "@mui/material/IconButton";
// import ArticleIcon from "@mui/icons-material/Article";
// import MenuIcon from "@mui/icons-material/Menu";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import InfoIcon from "@mui/icons-material/Info";
// import InterestsIcon from "@mui/icons-material/Interests";
// import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
// import TranslateIcon from "@mui/icons-material/Translate";
// import ReportProblemIcon from "@mui/icons-material/ReportProblem";
// import { People } from "@mui/icons-material";

// import profile from "../../service/Mainservice";
import "./Sidebar.css";
import { useEffect } from "react";
import navlogo from "../../assets/Navlogo.png";
// import profile_pic from "../../assets/agent (2).png";

interface ResponsiveDrawerProps {
  window?: () => Window;
}

const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const location = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const profiledata = await profile();
        // setprofilepic(profiledata.data.data.profile_pic);
      } catch (error) {
        // if (error.code === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      }
    };
    fetchData();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const CheckPath = (path: string) => location.pathname === path;
  console.log(CheckPath("/Dashboard"));

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className="Sidebar" ref={sidebarRef}>
      <div>
        <div className="Auth-logo">
          <img src={navlogo} className="sidebar_logo" />
        </div>
        <ul className="Sidebarlist">
          <li
            className={
              CheckPath("/Dashboard") ? "Selectedsidebar" : "SidebarItem"
            }
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Dashboard">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Dashboard") ? (
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 31 32"
                    style={{ marginLeft: "-14px" }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_2_693)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11 17.713C11 12.082 11.614 12.475 14.919 9.41C16.365 8.246 18.615 6 20.558 6C22.5 6 24.795 8.235 26.254 9.41C29.559 12.475 30.172 12.082 30.172 17.713C30.172 26 28.213 26 20.586 26C12.959 26 11 26 11 17.713Z"
                        fill="#2C7873"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11 17.713C11 12.082 11.614 12.475 14.919 9.41C16.365 8.246 18.615 6 20.558 6C22.5 6 24.795 8.235 26.254 9.41C29.559 12.475 30.172 12.082 30.172 17.713C30.172 26 28.213 26 20.586 26C12.959 26 11 26 11 17.713Z"
                        stroke="#2C7873"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <path
                      d="M17.6797 20.1348H23.4947H17.6797Z"
                      fill="#2C7873"
                    />
                    <path
                      d="M17.6797 20.1348H23.4947"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <filter
                        id="filter0_d_2_693"
                        x="0.25"
                        y="0.25"
                        width="40.6719"
                        height="41.5"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="5" />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.172549 0 0 0 0 0.470588 0 0 0 0 0.45098 0 0 0 0.2 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_2_693"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_2_693"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="23"
                    height="31"
                    viewBox="10 0 22 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <g filter="url(#filter0_d_311_4580)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11 17.713C11 12.082 11.614 12.475 14.919 9.41C16.365 8.246 18.615 6 20.558 6C22.5 6 24.795 8.235 26.254 9.41C29.559 12.475 30.172 12.082 30.172 17.713C30.172 26 28.213 26 20.586 26C12.959 26 11 26 11 17.713Z"
                          stroke="#7C7C7D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          shape-rendering="crispEdges"
                        />
                      </g>
                      <path d="M17.679 20.1348H23.494H17.679Z" fill="#7C7C7D" />
                      <path
                        d="M17.679 20.1348H23.494"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_311_4580"
                        x="0.25"
                        y="0.25"
                        width="30.6719"
                        height="30.5"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="5" />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.172549 0 0 0 0 0.470588 0 0 0 0 0.45098 0 0 0 0.2 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_311_4580"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_311_4580"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                )}
                <p className="sidebarlabel">Dashboard</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>

          <li
            className={
              CheckPath("/Requests") || CheckPath("/AgencyRequest_preview")
                ? "Selectedsidebar"
                : "SidebarItem"
            }
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Requests">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Requests") ||
                CheckPath("/AgencyRequest_preview") ? (
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-8px" }}
                  >
                    <path
                      d="M9.745 1.4425C10.435 0.8525 11.565 0.8525 12.265 1.4425L13.845 2.8025C14.145 3.0625 14.705 3.2725 15.105 3.2725H16.805C17.865 3.2725 18.735 4.1425 18.735 5.2025V6.9025C18.735 7.2925 18.945 7.8625 19.205 8.1625L20.565 9.7425C21.155 10.4325 21.155 11.5625 20.565 12.2625L19.205 13.8425C18.945 14.1425 18.735 14.7025 18.735 15.1025V16.8025C18.735 17.8625 17.865 18.7325 16.805 18.7325H15.105C14.715 18.7325 14.145 18.9425 13.845 19.2025L12.265 20.5625C11.575 21.1525 10.445 21.1525 9.745 20.5625L8.165 19.2025C7.865 18.9425 7.305 18.7325 6.905 18.7325H5.175C4.115 18.7325 3.245 17.8625 3.245 16.8025V15.0925C3.245 14.7025 3.035 14.1425 2.785 13.8425L1.435 12.2525C0.855 11.5625 0.855 10.4425 1.435 9.7525L2.785 8.1625C3.035 7.8625 3.245 7.3025 3.245 6.9125V5.1925C3.245 4.1325 4.115 3.2625 5.175 3.2625H6.905C7.295 3.2625 7.865 3.0525 8.165 2.7925L9.745 1.4425Z"
                      fill="#2C7873"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.375 10.9903L9.785 13.4103L14.615 8.57031"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M7.375 10.9903L9.785 13.4103L14.615 8.57031"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.745 1.4425C10.435 0.8525 11.565 0.8525 12.265 1.4425L13.845 2.8025C14.145 3.0625 14.705 3.2725 15.105 3.2725H16.805C17.865 3.2725 18.735 4.1425 18.735 5.2025V6.9025C18.735 7.2925 18.945 7.8625 19.205 8.1625L20.565 9.7425C21.155 10.4325 21.155 11.5625 20.565 12.2625L19.205 13.8425C18.945 14.1425 18.735 14.7025 18.735 15.1025V16.8025C18.735 17.8625 17.865 18.7325 16.805 18.7325H15.105C14.715 18.7325 14.145 18.9425 13.845 19.2025L12.265 20.5625C11.575 21.1525 10.445 21.1525 9.745 20.5625L8.165 19.2025C7.865 18.9425 7.305 18.7325 6.905 18.7325H5.175C4.115 18.7325 3.245 17.8625 3.245 16.8025V15.0925C3.245 14.7025 3.035 14.1425 2.785 13.8425L1.435 12.2525C0.855 11.5625 0.855 10.4425 1.435 9.7525L2.785 8.1625C3.035 7.8625 3.245 7.3025 3.245 6.9125V5.1925C3.245 4.1325 4.115 3.2625 5.175 3.2625H6.905C7.295 3.2625 7.865 3.0525 8.165 2.7925L9.745 1.4425Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                )}
                <p className="sidebarlabel">Requests</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>
          <li
            className={
              CheckPath("/Orders") || CheckPath("/Orderdetails")
                ? "Selectedsidebar"
                : "SidebarItem"
            }
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Orders">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Orders") || CheckPath("/Orderdetails") ? (
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    style={{ marginLeft: "-5px" }}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="1">
                      <path
                        d="M8.54 1.48126L3.2 4.44126C1.99 5.11126 1 6.79126 1 8.17126V13.8213C1 15.2013 1.99 16.8813 3.2 17.5513L8.54 20.5213C9.68 21.1513 11.55 21.1513 12.69 20.5213L18.03 17.5513C19.24 16.8813 20.23 15.2013 20.23 13.8213V8.17126C20.23 6.79126 19.24 5.11126 18.03 4.44126L12.69 1.47126C11.54 0.841257 9.68 0.841257 8.54 1.48126Z"
                        fill="#2C7873"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />

                      <path
                        d="M1.78003 6.44141L10.61 11.5514L19.38 6.47141"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.61 20.611V11.541"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M1.78003 6.44141L10.61 11.5514L19.38 6.47141"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.61 20.611V11.541"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.54 1.48126L3.2 4.44126C1.99 5.11126 1 6.79126 1 8.17126V13.8213C1 15.2013 1.99 16.8813 3.2 17.5513L8.54 20.5213C9.68 21.1513 11.55 21.1513 12.69 20.5213L18.03 17.5513C19.24 16.8813 20.23 15.2013 20.23 13.8213V8.17126C20.23 6.79126 19.24 5.11126 18.03 4.44126L12.69 1.47126C11.54 0.841257 9.68 0.841257 8.54 1.48126Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                )}
                <p className="sidebarlabel">Orders</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>
          <li
            className={
              CheckPath("/Tickets") || CheckPath("/Ticketdetails")
                ? "Selectedsidebar"
                : "SidebarItem"
            }
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Tickets">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Tickets") || CheckPath("/Ticketdetails") ? (
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 22 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-2px", marginTop: "-4px" }}
                  >
                    <path d="M0.810059 1V25V1Z" fill="#2C7873" />
                    <path
                      d="M0.810059 1V25"
                      stroke="#2C7873"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M0.810059 3.40234H14.2501C17.4901 3.40234 18.2101 5.20234 15.9301 7.48234L14.4901 8.92234C13.5301 9.88234 13.5301 11.4423 14.4901 12.2823L15.9301 13.7223C18.2101 16.0023 17.3701 17.8023 14.2501 17.8023H0.810059"
                      fill="#2C7873"
                    />
                    <path
                      d="M0.810059 3.40234H14.2501C17.4901 3.40234 18.2101 5.20234 15.9301 7.48234L14.4901 8.92234C13.5301 9.88234 13.5301 11.4423 14.4901 12.2823L15.9301 13.7223C18.2101 16.0023 17.3701 17.8023 14.2501 17.8023H0.810059"
                      stroke="#2C7873"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="17"
                    height="24"
                    viewBox="0 0 17 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "2px" }}
                  >
                    <g opacity="0.5">
                      <path
                        d="M0.815186 1V22.7287"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.815186 3.17383H12.9833C15.9167 3.17383 16.5685 4.80348 14.5043 6.86771L13.2006 8.17144C12.3314 9.04059 12.3314 10.453 13.2006 11.2135L14.5043 12.5172C16.5685 14.5814 15.808 16.2111 12.9833 16.2111H0.815186"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                )}{" "}
                <p className="sidebarlabel">Active Tickets</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>
          <li
            className={
              CheckPath("/Reports") ? "Selectedsidebar" : "SidebarItem"
            }
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Reports">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Reports") ? (
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-3px", marginTop: "-6px" }}
                  >
                    <path
                      d="M12.1672 0H6.99702C5.85299 0 4.91797 0.924024 4.91797 2.06805V3.10208C4.91797 4.24611 5.84199 5.17013 6.98602 5.17013H12.1672C13.3112 5.17013 14.2352 4.24611 14.2352 3.10208V2.06805C14.2462 0.924024 13.3112 0 12.1672 0Z"
                      fill="#2C7873"
                    />
                    <path
                      d="M15.3454 3.10029C15.3454 4.84933 13.9154 6.27937 12.1663 6.27937H6.99618C5.24713 6.27937 3.8171 4.84933 3.8171 3.10029C3.8171 2.48427 3.15708 2.09926 2.60707 2.38527C1.05603 3.21029 0 4.84933 0 6.73038V17.0816C0 19.7877 2.21106 21.9988 4.91713 21.9988H14.2454C16.9514 21.9988 19.1625 19.7877 19.1625 17.0816V6.73038C19.1625 4.84933 18.1065 3.21029 16.5554 2.38527C16.0054 2.09926 15.3454 2.48427 15.3454 3.10029ZM13.4643 17.0816C13.2993 17.2467 13.0903 17.3237 12.8813 17.3237C12.6723 17.3237 12.4633 17.2467 12.2983 17.0816L7.10618 11.8895V13.1985C7.10618 13.6496 6.73217 14.0236 6.28116 14.0236C5.83015 14.0236 5.45614 13.6496 5.45614 13.1985V9.89846C5.45614 9.44745 5.83015 9.07344 6.28116 9.07344H9.58124C10.0323 9.07344 10.4063 9.44745 10.4063 9.89846C10.4063 10.3495 10.0323 10.7235 9.58124 10.7235H8.27221L13.4643 15.9156C13.7833 16.2346 13.7833 16.7626 13.4643 17.0816Z"
                      fill="#2C7873"
                    />
                  </svg>
                ) : (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M8 5H12C14 5 14 4 14 3C14 1 13 1 12 1H8C7 1 6 1 6 3C6 5 7 5 8 5Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 20.9995H7C2 20.9995 1 18.9995 1 14.9995V8.99953C1 4.43953 2.67 3.19953 6 3.01953"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 3.01953C17.33 3.19953 19 4.42953 19 8.99953V13.9995"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13 18V15H16"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19 20.9991L13.04 15.0391"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                )}
                <p className="sidebarlabel">Reports</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>
          <li
            className={
              CheckPath("/Transections") ? "Selectedsidebar" : "SidebarItem"
            }
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Transections">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Transections") ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-3px", marginTop: "-4px" }}
                  >
                    <g opacity="1">
                      <path
                        d="M19.9998 13.2949L14.4004 18.9055"
                        stroke="#2C7873"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 13.2949H20"
                        stroke="#2C7873"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 6.61059L6.59941 1"
                        stroke="#2C7873"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 6.60938H1"
                        stroke="#2C7873"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M19.9998 13.2949L14.4004 18.9055"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 13.2949H20"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 6.61059L6.59941 1"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 6.60938H1"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                )}
                <p className="sidebarlabel">Transections</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>
          <li
            className={
              CheckPath("/Users") || CheckPath("/Userdetails")
                ? "Selectedsidebar"
                : "SidebarItem"
            }
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Users">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Users") || CheckPath("/Userdetails") ? (
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-5px", marginTop: "-5px" }}
                  >
                    <g opacity="1">
                      <path
                        d="M10.5898 11C13.3513 11 15.5898 8.76142 15.5898 6C15.5898 3.23858 13.3513 1 10.5898 1C7.82842 1 5.58984 3.23858 5.58984 6C5.58984 8.76142 7.82842 11 10.5898 11Z"
                        stroke="#2C7873"
                        fill="#2C7873"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 21C20 17.13 15.7421 14 10.5 14C5.25786 14 1 17.13 1 21"
                        stroke="#2C7873"
                        fill="#2C7873"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M10.5898 11C13.3513 11 15.5898 8.76142 15.5898 6C15.5898 3.23858 13.3513 1 10.5898 1C7.82842 1 5.58984 3.23858 5.58984 6C5.58984 8.76142 7.82842 11 10.5898 11Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 21C20 17.13 15.7421 14 10.5 14C5.25786 14 1 17.13 1 21"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                )}
                <p className="sidebarlabel">Users/Clients</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>
          <li
            className={CheckPath("/Agency") ? "Selectedsidebar" : "SidebarItem"}
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Agency">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Agency") ? (
                  <svg
                    width="21"
                    height="23"
                    viewBox="0 0 21 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-5px", marginTop: "-4px" }}
                  >
                    <g opacity="1">
                      <path
                        d="M14.7077 11.5843C17.6304 11.5843 19.9998 9.21491 19.9998 6.29214C19.9998 3.36938 17.6304 1 14.7077 1C11.7849 1 9.41553 3.36938 9.41553 6.29214C9.41553 9.21491 11.7849 11.5843 14.7077 11.5843Z"
                        stroke="#2C7873"
                        fill="#2C7873"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M5.33033 18.5376C7.32442 18.5376 8.94097 16.9211 8.94097 14.927C8.94097 12.9329 7.32442 11.3164 5.33033 11.3164C3.33624 11.3164 1.71973 12.9329 1.71973 14.927C1.71973 16.9211 3.33624 18.5376 5.33033 18.5376Z"
                        stroke="#2C7873"
                        fill="#2C7873"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M15.7703 21.6334C17.2288 21.6334 18.4112 20.451 18.4112 18.9925C18.4112 17.5339 17.2288 16.3516 15.7703 16.3516C14.3118 16.3516 13.1294 17.5339 13.1294 18.9925C13.1294 20.451 14.3118 21.6334 15.7703 21.6334Z"
                        stroke="#2C7873"
                        fill="#2C7873"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    width="21"
                    height="23"
                    viewBox="0 0 21 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M14.7077 11.5843C17.6304 11.5843 19.9998 9.21491 19.9998 6.29214C19.9998 3.36938 17.6304 1 14.7077 1C11.7849 1 9.41553 3.36938 9.41553 6.29214C9.41553 9.21491 11.7849 11.5843 14.7077 11.5843Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M5.33033 18.5376C7.32442 18.5376 8.94097 16.9211 8.94097 14.927C8.94097 12.9329 7.32442 11.3164 5.33033 11.3164C3.33624 11.3164 1.71973 12.9329 1.71973 14.927C1.71973 16.9211 3.33624 18.5376 5.33033 18.5376Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M15.7703 21.6334C17.2288 21.6334 18.4112 20.451 18.4112 18.9925C18.4112 17.5339 17.2288 16.3516 15.7703 16.3516C14.3118 16.3516 13.1294 17.5339 13.1294 18.9925C13.1294 20.451 14.3118 21.6334 15.7703 21.6334Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                    </g>
                  </svg>
                )}
                <p className="sidebarlabel">Agency /Office</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>
          <li
            className={
              CheckPath("/Setting/Notification")
                ? "Selectedsidebar"
                : "SidebarItem"
            }
            onClick={() => setMobileOpen(false)}
          >
            <Link to="/Setting/Notification">
              <div className="sidebarline" />
              <div className="Sidebarmenues">
                {CheckPath("/Setting/Notification") ? (
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-3px", marginTop: "-2px" }}
                  >
                    <g opacity="1">
                      <path
                        d="M1 8V13.77C1 15.89 1 15.89 3 17.24L8.5 20.42C9.33 20.9 10.68 20.9 11.5 20.42L17 17.24C19 15.89 19 15.89 19 13.78V8C19 5.89 19 5.89 17 4.54L11.5 1.36C10.68 0.88 9.33 0.88 8.5 1.36L3 4.54C1 5.89 1 5.89 1 8Z"
                        stroke="#2C7873"
                        fill="#2C7873"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 13.8906C11.6569 13.8906 13 12.5475 13 10.8906C13 9.23377 11.6569 7.89062 10 7.89062C8.34315 7.89062 7 9.23377 7 10.8906C7 12.5475 8.34315 13.8906 10 13.8906Z"
                        stroke="#fff"
                        fill="#2C7873"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-3px" }}
                  >
                    <g opacity="0.5">
                      <path
                        d="M1 8V13.77C1 15.89 1 15.89 3 17.24L8.5 20.42C9.33 20.9 10.68 20.9 11.5 20.42L17 17.24C19 15.89 19 15.89 19 13.78V8C19 5.89 19 5.89 17 4.54L11.5 1.36C10.68 0.88 9.33 0.88 8.5 1.36L3 4.54C1 5.89 1 5.89 1 8Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 13.8906C11.6569 13.8906 13 12.5475 13 10.8906C13 9.23377 11.6569 7.89062 10 7.89062C8.34315 7.89062 7 9.23377 7 10.8906C7 12.5475 8.34315 13.8906 10 13.8906Z"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                )}

                <p className="sidebarlabel">Setting</p>
              </div>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="sidebararrow"
              >
                <path
                  d="M1.08789 1L7.06516 7L1.08789 13"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
