// import Pagination from "../Pagination/Pagination";
import { useEffect, useRef, useState } from "react";
import Header from "../../Layout/Header";
import profileimg from "../../assets/profile.png";
import { requestlist } from "../../service/Mainservice";
import Menu from "../Dashboard/ActionMenu";
import { useNavigate } from "react-router-dom";
import Logout from "../../Layout/Logout";
import Loader from "../../Loader/Loader";

const Allrequest = () => {
  const [loader, setloader] = useState(true);

  const [list, setlist] = useState<any>([]);
  const [totalpage, settotalpage] = useState(1);
  const [currentpage, setcurrentpage] = useState(1);
  const [totalitem, settotalitem] = useState(0);
  const [activestatus, setactivestatus] = useState("Pending");
  const [modalPosition, setModalPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [menuopen, setmenuopen] = useState<{ [key: number]: boolean }>({});
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<SVGSVGElement>(null);
  const navigate = useNavigate();
  useEffect(() => {
    getrequestlist("1");
  }, []);

  const getrequestlist = async (page: string) => {
    setloader(true);

    try {
      const response = await requestlist(page);
      setloader(false);

      if (response.status === true) {
        setlist(response.data);
        settotalitem(response.totalItems);
        settotalpage(response.totalPages);
        setcurrentpage(response.currentPage);
      } else {
        setlist([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleModalPosition = (
    e: React.MouseEvent<SVGSVGElement>,
    index: number
  ) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    setmenuopen({
      ...menuopen,
      [index]: !menuopen[index],
    });
    setModalPosition({
      top: buttonRect.bottom + 10,
      left: buttonRect.left - 60,
    });
  };

  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">Request</h1>
        <Logout />
      </div>

      <div className="Dashboard_screen">
        <div className="Trasaction_main">
          <h4 className="Request_title">Translator Requests</h4>
        </div>
        <div className="Request_status">
          <p
            className={
              activestatus === "Pending"
                ? "Request_status_name active_name"
                : "Request_status_name"
            }
            onClick={() => setactivestatus("Pending")}
          >
            Pending
          </p>
          <p
            className={
              activestatus === "Accepted"
                ? "Request_status_name active_name"
                : "Request_status_name"
            }
            onClick={() => setactivestatus("Accepted")}
          >
            Accepted
          </p>
          <p
            className={
              activestatus === "Rejected"
                ? "Request_status_name active_name"
                : "Request_status_name"
            }
            onClick={() => setactivestatus("Rejected")}
          >
            Rejected
          </p>
        </div>
        <div className="Transaction_table_data">
          <table className="Request_table">
            <thead>
              <tr>
                <th className="transection_head_title">Name</th>
                <th>Country </th>
                <th>Mobile </th>
                <th>Email</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((data: any, index: number) => (
                <tr>
                  <td className="table_userinfo">
                    <img src={profileimg} alt="" />
                    {data.agency_detail.fullname}
                  </td>
                  <td>{data.country_code}</td>
                  <td>{data.phone_number}</td>
                  <td>{data.email}</td>
                  <td>{new Date(data.createdtimestamp).toDateString()}</td>
                  <td>
                    <span
                      className="pending_data"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/AgencyRequest_preview", {
                          state: {
                            id: data.id,
                          },
                        })
                      }
                    >
                      View
                    </span>
                  </td>
                  {/* <td className="action_menu">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      ref={buttonRef}
                      onClick={(e) => handleModalPosition(e, index)}
                      style={{ cursor: "pointer" }}
                    >
                      <rect
                        opacity="0.05"
                        width="36"
                        height="36"
                        rx="18"
                        fill="#0A0F0F"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24 14.4395C24 15.7914 22.9034 16.8887 21.5507 16.8887C20.1988 16.8887 19.1028 15.7914 19.1028 14.4395C19.1028 13.0869 20.1988 11.9902 21.5507 11.9902C22.9034 11.9902 24 13.0869 24 14.4395Z"
                        stroke="#0A0F0F"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.8981 14.4395C16.8981 15.7914 15.8015 16.8887 14.4488 16.8887C13.0969 16.8887 12.0002 15.7914 12.0002 14.4395C12.0002 13.0869 13.0969 11.9902 14.4488 11.9902C15.8015 11.9902 16.8981 13.0869 16.8981 14.4395Z"
                        stroke="#0A0F0F"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24 21.4981C24 22.8507 22.9034 23.9467 21.5507 23.9467C20.1988 23.9467 19.1028 22.8507 19.1028 21.4981C19.1028 20.1455 20.1988 19.0488 21.5507 19.0488C22.9034 19.0488 24 20.1455 24 21.4981Z"
                        stroke="#0A0F0F"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.8981 21.4981C16.8981 22.8507 15.8015 23.9467 14.4488 23.9467C13.0969 23.9467 12.0002 22.8507 12.0002 21.4981C12.0002 20.1455 13.0969 19.0488 14.4488 19.0488C15.8015 19.0488 16.8981 20.1455 16.8981 21.4981Z"
                        stroke="#0A0F0F"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {menuopen[index] && (
                      <Menu
                        showmenu={menuopen}
                        setshowmenu={setmenuopen}
                        modalref={modalRef}
                        id={data.id}
                        index={index}
                        modalPosition={modalPosition}
                      />
                    )}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};
export default Allrequest;
