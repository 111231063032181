import React, { useEffect, useRef, useState } from "react";
import NotificationModal from "./MainLayout/Notification";
import Menu from "./MainLayout/Menu";
import profileimg from "../assets/profile.png";

const Header = () => {
  const [shownotification, setshownotification] = useState(false);
  const [showmenu, setshowmenu] = useState(false);
  const [selectednf, setselectednf] = useState(false);
  const [selectedprofile, setselectedprofile] = useState(false);
  const [profilepic, setprofilepic] = useState(false);
  const menuref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    localStorage.getItem("isuser");
    const handler = (event: MouseEvent) => {
      if (menuref.current && !menuref.current.contains(event.target as Node)) {
        setshownotification(false);
        setshowmenu(false);
        setselectednf(false);
        setselectedprofile(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const nfmenu = () => {
    setshownotification(true);
    setselectednf(true);
  };
  const profilemenu = () => {
    setshowmenu(true);
    setselectedprofile(true);
  };
  return (
    <>
      <div className="header_appbar">
        <div className={selectednf ? "header_nf selectednf" : "header_nf"}>
          <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={nfmenu}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.38122 2C3.94722 2 2.02023 6.015 2.02023 8.67C2.02023 10.653 2.30823 10.07 1.21024 12.49C-0.130763 15.938 5.26122 17.348 8.38122 17.348C11.5002 17.348 16.8932 15.938 15.5532 12.49C14.4542 10.07 14.7422 10.653 14.7422 8.67C14.7422 6.015 12.8142 2 8.38122 2Z"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.6913 19.998C9.39725 21.444 7.37826 21.461 6.07126 19.998"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <circle
              cx="14.5"
              cy="3.5"
              r="2.75"
              fill="#FF3868"
              stroke="#F8F8F8"
              stroke-width="1.5"
            />
          </svg>
        </div>
        <div className={selectedprofile ? "header_nf selectednf" : "header_nf"}>
          <img
            alt="profileimg"
            // src={process.env.REACT_APP_IMAGE_URL_ADMIN + profile}
            src={profileimg}
            className="appbarprofileimg"
            onClick={profilemenu}
          />
        </div>
        <div>
          {shownotification && (
            <NotificationModal shownf={setshownotification} menuref={menuref} />
          )}
          {showmenu && <Menu showmenu={setshowmenu} menuref={menuref} />}
        </div>
      </div>
    </>
  );
};
export default Header;
