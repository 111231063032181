import { useEffect, useRef, useState } from "react";
// import dayjs from "dayjs";
import ReactApexChart from "react-apexcharts";
import Header from "../../Layout/Header";
import { useNavigate } from "react-router-dom";
// import { dashboard } from "../../service/Mainservice";
import chatprofile from "../../assets/chatprofile.png";
import "./Report.css";
const Reports = () => {
  const [loading, setloading] = useState(true);
  const [menuopen, setmenuopen] = useState(false);
  const [details, setdetails] = useState({});
  const navigate = useNavigate();
  const menuref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (menuref.current && !menuref.current.contains(event.target as Node)) {
        setmenuopen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // useEffect(() => {
  //   getData();
  // }, []);

  // const getData = async () => {
  //   try {
  //     const data = await dashboard();
  //     setloading(false);
  //     setdetails(data.data.data);
  //   } catch (error) {
  //     setloading(false);
  //     if (error.code === 401) {
  //       localStorage.clear();
  //       navigate("/");
  //     }
  //   }
  // };

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "",
        data: [
          {
            x: "Jan",
            y: 40,
          },
          {
            x: "Feb",
            y: 60,
          },
          {
            x: "Mar",
            y: 80,
          },
          {
            x: "Apr",
            y: 20,
          },
          {
            x: "May",
            y: 40,
          },
          {
            x: "Jun",
            y: 10,
          },
          {
            x: "Jul",
            y: 60,
          },
          {
            x: "Aug",
            y: 40,
          },
          {
            x: "Sep",
            y: 20,
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 180,
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      fill: {
        colors: ["var(--appcolor)", "var(--textgrey)"],
      },
      xaxis: {
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        // stepSize: 20, here height is fixed so ui design effected
      },

      title: {
        text: "",
      },
    } as ApexCharts.ApexOptions,
  });
  const [chart2Data, setChart2Data] = useState({
    series: [
      {
        name: "series1",
        data: [4, 30, 4, 70.1, 10, 4, 50],
      },
    ],

    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["var(--appcolor)"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0,
          opacityFrom: 0.1,
          opacityTo: 0,
          stops: [44, 120, 115],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
        ],
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return val + "k";
          },
        },
      },
    } as ApexCharts.ApexOptions,
  });
  const [donutchart, setdonutchart] = useState({
    series: [44, 55, 41],
    //   {
    //     // name: "Agency,Users,Tickets",
    //     data: [44, 55, 41],
    //   },
    // ],

    options: {
      chart: {
        type: "donut",
      },
      legend: {
        position: "bottom",
      },
      colors: ["var(--appcolor)", "var(--red)", "var(--pending)"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        lineCap: "round",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
        {
          breakpoint: 1020,
          options: {
            chart: {
              width: 350,
            },
          },
        },
      ],
    } as ApexCharts.ApexOptions,
  });

  const [circle, setcircle] = useState({
    series: [84],
    option: {
      chart: {
        type: "radialBar",
      },
      colors: ["var(--appcolor)"],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 40,
            size: "60%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: true,
              color: "var(--textgrey)",
              fontSize: "13px",
              fontWeight: "normal",
            },
            value: {
              color: "var(--black)",
              fontSize: "32px",
              fontWeight: "bold",
              show: true,
            },
          },
        },
      },

      stroke: {
        lineCap: "round",
      },
      labels: ["Is Solved"],
    } as ApexCharts.ApexOptions,
  });
  const [circle2, setcircle2] = useState({
    series: [48],
    option: {
      chart: {
        type: "radialBar",
      },
      colors: ["var(--pending)"],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 40,
            size: "60%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: true,
              color: "var(--textgrey)",
              fontSize: "13px",
              fontWeight: "normal",
            },
            value: {
              color: "var(--black)",
              fontSize: "32px",
              fontWeight: "bold",
              show: true,
            },
          },
        },
      },

      stroke: {
        lineCap: "round",
      },
      labels: ["Is Solved"],
    } as ApexCharts.ApexOptions,
  });
  const [circle3, setcircle3] = useState({
    series: [55],
    option: {
      chart: {
        type: "radialBar",
      },
      colors: ["var(--cyan)"],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 40,
            size: "60%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: true,
              color: "var(--textgrey)",
              fontSize: "13px",
              fontWeight: "normal",
            },
            value: {
              color: "var(--black)",
              fontSize: "32px",
              fontWeight: "bold",
              show: true,
            },
          },
        },
      },

      stroke: {
        lineCap: "round",
      },
      labels: ["Is Solved"],
    } as ApexCharts.ApexOptions,
  });
  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">Reports</h1>
        <div className="header_search">
          <svg
            width="15"
            height="15"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <circle cx="8.01515" cy="8.51515" r="7.51515" stroke="#0A0F0F" />
              <path
                d="M13.6514 14.1514L15.9999 16.4999"
                stroke="#0A0F0F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>

          <input
            type="search"
            name="languagesearch"
            id="languagesearch"
            placeholder="Find the language you offer"
          />
        </div>

        <Header />
      </div>
      <div className="Dashboard_screen">
        <div className="Dashboard_main">
          <div className="Dashboard_left">
            <div className="Dashboard_chart">
              <div className="Reports_charttitle">
                <div>
                  <div className="Dashboard_order">
                    <p className="Dashboard_order_name active_order_name">
                      Overall Revenue
                    </p>
                    <p className="Dashboard_order_name">Agency Revenue</p>
                    <p className="Dashboard_order_name">Total Transection</p>
                  </div>
                  <h2 className="Dashboard_ordernum">$657,587 USD</h2>
                </div>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="52" height="52" rx="26" fill="#2C7873" />
                  <path
                    d="M29.7562 21.3906C32.7879 21.6517 34.0258 23.2096 34.0258 26.6203V26.7298C34.0258 30.4941 32.5184 32.0015 28.7541 32.0015H23.2718C19.5074 32.0015 18 30.4941 18 26.7298V26.6203C18 23.2349 19.2211 21.677 22.2023 21.399"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M26.0176 26.5269V16.9434"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.8386 18.8212L26.0174 16L23.1963 18.8212"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="Dashboard_overview">
                <h4 className="Dashboard_overview_title">Overview</h4>
                <div className="Dashboard_orderdata">
                  <p>
                    Total Revenue{" "}
                    <svg
                      width="9"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6.5 6L12 1"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                  <p>
                    Monthly{" "}
                    <svg
                      width="9"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6.5 6L12 1"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div id="chart">
                <ReactApexChart
                  options={chart2Data.options}
                  series={chart2Data.series}
                  type="area"
                  height={218}
                />
              </div>
            </div>
            <div className="Dashboard_chart">
              <div className="Reports_charttitle">
                <div>
                  <div className="Dashboard_order">
                    <p className="Dashboard_order_name active_order_name">
                      Order Ratio
                    </p>
                    <p className="Dashboard_order_name">Earning Ratio</p>
                    <p className="Dashboard_order_name">Ticket Ratio</p>
                  </div>

                  <h2 className="Dashboard_ordernum">16</h2>
                </div>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="52" height="52" rx="26" fill="#2C7873" />
                  <path
                    d="M29.7562 21.3906C32.7879 21.6517 34.0258 23.2096 34.0258 26.6203V26.7298C34.0258 30.4941 32.5184 32.0015 28.7541 32.0015H23.2718C19.5074 32.0015 18 30.4941 18 26.7298V26.6203C18 23.2349 19.2211 21.677 22.2023 21.399"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M26.0176 26.5269V16.9434"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.8386 18.8212L26.0174 16L23.1963 18.8212"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="Dashboard_overview">
                <h4 className="Dashboard_overview_title">Overview</h4>
                <div className="Dashboard_orderdata">
                  <p>
                    Total Order{" "}
                    <svg
                      width="9"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6.5 6L12 1"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                  <p>
                    Monthly{" "}
                    <svg
                      width="9"
                      height="7"
                      viewBox="0 0 13 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6.5 6L12 1"
                        stroke="#7C7C7D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div id="chart">
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={250}
                />
              </div>
              <div className="report_circlechart">
                <div id="chartcircle">
                  <div className="Dashboard_titlediv">
                    <h3>Tickets</h3>
                  </div>
                  <ReactApexChart
                    options={circle.option}
                    series={circle.series}
                    type="radialBar"
                    height={218}
                  />
                </div>
                <div id="chartcircle">
                  <div className="Dashboard_titlediv">
                    <h3>Daily Payouts</h3>
                  </div>

                  <ReactApexChart
                    options={circle2.option}
                    series={circle2.series}
                    type="radialBar"
                    height={218}
                  />
                </div>
                <div id="chartcircle">
                  <div className="Dashboard_titlediv">
                    <h3>Re-Orders</h3>
                  </div>

                  <ReactApexChart
                    options={circle3.option}
                    series={circle3.series}
                    type="radialBar"
                    height={218}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Dashboard_right">
            <div className="Dashboard_Divs">
              <div className="Dashboard_titlediv">
                <h3>Overall Activity</h3>
              </div>

              <ReactApexChart
                options={donutchart.options}
                series={donutchart.series}
                type="donut"
              />
            </div>
            <div className="Dashboard_Divs">
              <div className="Dashboard_titlediv">
                <h3>Top 5 Users</h3>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <div className="report_moneyshow">
                  <p className="report_moneyshow_amount">$58.9M</p>
                  <p>
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6 6L1 11"
                        stroke="#7C7C7D"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <div className="report_moneyshow">
                  <p className="report_moneyshow_amount">$58.9M</p>
                  <p>
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6 6L1 11"
                        stroke="#7C7C7D"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <div className="report_moneyshow">
                  <p className="report_moneyshow_amount">$58.9M</p>
                  <p>
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6 6L1 11"
                        stroke="#7C7C7D"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <div className="report_moneyshow">
                  <p className="report_moneyshow_amount">$58.9M</p>
                  <p>
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6 6L1 11"
                        stroke="#7C7C7D"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <div className="report_moneyshow">
                  <p className="report_moneyshow_amount">$58.9M</p>
                  <p>
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.5"
                        d="M1 1L6 6L1 11"
                        stroke="#7C7C7D"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                </div>
              </div>
            </div>
            <div className="Dashboard_Divs" style={{ border: "None" }}>
              <div className="Dashboard_titlediv">
                <h3>Top 5 Agency</h3>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_transation_money">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M1 1L6 6L1 11"
                      stroke="#7C7C7D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_transation_money">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M1 1L6 6L1 11"
                      stroke="#7C7C7D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_transation_money">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M1 1L6 6L1 11"
                      stroke="#7C7C7D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_transation_money">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M1 1L6 6L1 11"
                      stroke="#7C7C7D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </div>
              <div className="Dashboard_transation_list">
                <div className="Dashboard_transation_user">
                  <img src={chatprofile} alt="" />
                  <div className="Dashboard_transation_userdetail">
                    <h2 className="Dashboard_transation_username">
                      David Mc.Hamlin
                    </h2>
                    <div className="Dashboard_transation_datestatus">
                      <p>Ecotown, AUS </p>
                    </div>
                  </div>
                </div>
                <p className="Dashboard_transation_money">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M1 1L6 6L1 11"
                      stroke="#7C7C7D"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Reports;
