import { NavLink } from "react-router-dom";
import "./Notification.css";
const Notification = () => {
  return (
    <>
      <div className="Notificationbox">
        <h1 className="Notificationtitle">Notification</h1>
        <p className="Notificationdes">
          Lorem ipsum dolor sit amet consectetur. Fames integer amet elementum
          arcu massa erat. Nisl at lobn p et. Auctor tempor urna purus mattis
          vel in. Vel tellus eget amet est consectetur.
        </p>
        <div className="Notification_slide">
          <NavLink
            to="/Setting/Notification"
            className="Notification_slidename"
          >
            Push Notification
          </NavLink>
          <div className="Notification_slidename">Offer Notification</div>
          <div className="Notification_slidename">Selective Notification</div>
        </div>
        <div className="Notification_input">
          <div className="Notification_inputdiv">
            <label htmlFor="Notificationtitle" className="Notification_label">
              Notification Title *
            </label>
            <input
              type="text"
              name="Notificationtitle"
              className="auth_inputfield"
              value="Ahmed Abdullah"
            />
          </div>
          <div className="Notification_inputdiv">
            <label htmlFor="Notificationtitle" className="Notification_label">
              Select Users*
            </label>
            <select name="Notificationtitle" className="auth_inputfield">
              <option value="">All Users</option>
              <option value="">All Agency</option>
              <option value="">Both</option>
            </select>
          </div>
        </div>
        <label htmlFor="detail" className="Notification_label">
          Notification Details *
        </label>
        <textarea
          name="detail"
          id="detail"
          cols={15}
          rows={7}
          placeholder="Type your detail here..."
          className="auth_inputfield"
        ></textarea>
        <button className="Notification_button">Send Notification</button>
      </div>
    </>
  );
};
export default Notification;
