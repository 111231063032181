// import Pagination from "../Pagination/Pagination";
import { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import { orderlist } from "../../service/Mainservice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Logout from "../../Layout/Logout";
import ReactApexChart from "react-apexcharts";
import Loader from "../../Loader/Loader";
import DatePop from "../Date";

const Orders = () => {
  const [loader, setloader] = useState(true);
  const [showdate, setshowdate] = useState(false);
  const [list, setlist] = useState<any>([]);
  const [totalpage, settotalpage] = useState(1);
  const [currentpage, setcurrentpage] = useState(1);
  const [totalitem, settotalitem] = useState(0);
  const [searchby, setsearchby] = useState("All");
  const [search, setsearch] = useState("");
  const [chartData, setChartData] = useState({
    series: [44, 55, 13, 43, 22, 12, 23],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      legend: {
        position: "bottom",
      },

      labels: [
        "Complete",
        "Pending",
        "Cancel",
        "New",
        "Active",
        "Revision1",
        "Revision2",
      ],
      colors: [
        "var(--textgrey",
        "var(--pending)",
        "var(--red)",
        "var(--blue)",
        "var(--green)",
        "var(--rev1)",
        "var(--rev2)",
      ],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    } as ApexCharts.ApexOptions,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getlist("1");
  }, []);

  useEffect(() => {
    getlist("1");
  }, [search]);

  const getlist = async (page: string) => {
    setloader(true);

    try {
      const response = await orderlist(page, searchby, search);
      setloader(false);

      if (response.status === true) {
        setlist(response.data);
        settotalitem(response.totalItems);
        settotalpage(response.totalPages);
        setcurrentpage(response.currentPage);
      } else {
        setlist([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">Orders</h1>
        <Logout />
      </div>

      <div className="Dashboard_screen">
        <div className="Trasaction_main">
          <div className="Dashboard_overview">
            <div>
              <h4 className="Dashboard_overview_title">Orders Statics</h4>
            </div>
          </div>
          <div className="Transection_data">
            {/* <div className="Order_static">
              <label htmlFor="date">Date Range: </label> <br />
              <label htmlFor="sdate">start: </label> 
              <input
                type="sdate"
                name="date"
                id=""
                onClick={() => setshowdate(true)}
              />
               <label htmlFor="date">End: </label>
              <input type="date" name="date" id="" />        
                   </div> */}
            <div className="Order_static">
              <label htmlFor="search">Search By: </label>
              <select onChange={(e) => setsearchby(e.target.value)}>
                <option value="All">All</option>
                <option value="ID">ID</option>
                <option value="Translator">Translators</option>
                <option value="Price">Price</option>
                <option value="Currency">Currency</option>
                <option value="Date">Date</option>
                <option value="Deadline">Deadline</option>
                <option value="Submission">Submission</option>
              </select>
            </div>
            <div className="Order_static">
              <label htmlFor="search">Search: </label>
              <input
                type="search"
                name="=search"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
              />
            </div>
          </div>
          <div className="chart">
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="pie"
              width={380}
            />
          </div>

          <div className="Dashboard_overview">
            <div>
              <h4 className="Dashboard_overview_title">Orders List</h4>
            </div>
          </div>
        </div>
        <div className="Transaction_table_data">
          <table className="Request_table">
            <thead>
              <tr>
                <th className="table_userinfo">Id</th>
                {/* <th>Translator</th> */}
                <th>Category</th>
                <th>Date</th>
                <th>Deadline</th>
                <th>Submission</th>
                <th>Late</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((data: any) => {
                const [date, timeWithTimezone] = moment(Number(data.order_date))
                  .format()
                  .split("T");

                const datetime = timeWithTimezone.split("+")[0].split("-")[0];

                const [subdate, timeWithTimezoneS] = moment(
                  Number(data.complete_date)
                )
                  .format()
                  .split("T");

                const subtime = timeWithTimezoneS.split("+")[0].split("-")[0];
                const [deaddate, timeWithTimezoneD] = moment(
                  Number(data.order_deadline)
                )
                  .format()
                  .split("T");

                const deadtime = timeWithTimezoneD.split("+")[0].split("-")[0];

                const late = data.complete_date
                  ? new Date(Number(data.complete_date)).getTime() -
                    new Date(Number(data.order_deadline)).getTime()
                  : 0;
                return (
                  <tr>
                    <td style={{ paddingLeft: "25px" }}>{data.order_id}</td>
                    <td>{data.subject}</td>
                    <td>
                      {data.order_date ? (
                        <>
                          {date}
                          <br />
                          {datetime}
                        </>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      {data.order_deadline ? (
                        <>
                          {deaddate}
                          <br />
                          {deadtime}
                        </>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>
                      {data.complete_date ? (
                        <>
                          {subdate}
                          <br />
                          {subtime}
                        </>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>{late > 0 ? "Yes" : "No"}</td>
                    <td>
                      {data.status === 1 ? (
                        <span className="appactive_data">New</span>
                      ) : data.status === 2 ? (
                        <span className="pending_data">Pending</span>
                      ) : data.status === 3 ? (
                        <span className="active_data">Active</span>
                      ) : data.status === 4 ? (
                        <span className="cancel_data">Canceled</span>
                      ) : data.status === 5 ? (
                        <span className="complete_data">Completed</span>
                      ) : data.status === 6 ? (
                        <span className="active_data">Revision1</span>
                      ) : (
                        <span className="active_data">Revision2</span>
                      )}
                    </td>

                    <td>
                      <span
                        className="pending_data"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/Orderdetails", {
                            state: {
                              num: data.order_id,
                            },
                          })
                        }
                      >
                        View
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {loader && <Loader />}
      {/* <DatePop open={showdate} setopen={setshowdate} /> */}
    </>
  );
};
export default Orders;
