import "./auth.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getToken } from "firebase/messaging";
import { messaging } from "../../firebase";
import token, { login } from "../../service/Authservice";

interface Values {
  password: string;
  email: string;
}
const validate = (values: Values) => {
  const errors: Partial<Values> = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 6) {
    errors.password = "Password must be at least 6 characters";
  } else if (values.password.length > 20) {
    errors.password = "Must be 20 characters or less";
  }

  return errors;
};

const SignIn = () => {
  const [error, seterror] = useState("");
  const [open, setopen] = useState(false);
  const [dtoken, setdtoken] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        const permissionResult = await Notification.requestPermission();
        if (permissionResult === "granted") {
          const currentToken = await getToken(messaging, {
            vapidKey:
              "BAPPTzvQaoA-KYTvojlfpYeZ5vOzaBqwIWSfQu8soqgHSK8AF-UEqWxqgC27UfzTHfik8AmcCvq4WI11VAuiab4",
          });
          console.log(currentToken);
          setdtoken(currentToken);
        } else {
          console.log("Permission denied");
        }
      } catch (error) {
        console.error("Error obtaining device token:", error);
      }
    };

    requestNotificationPermission();
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setopen(false);
  };

  const generatetoken = async (value: Values) => {
    try {
      const response = await token(dtoken);
      localStorage.setItem("atkn", response);
      handlelogin(value);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validate,
    onSubmit: (values) => {
      generatetoken(values);
      console.log(values);
    },
  });
  const handlelogin = async (values: Values) => {
    try {
      const response = await login(values);
      console.log(response);
      localStorage.setItem("data", response.data.detail);
      navigate("/Dashboard");
    } catch (error: any) {
      console.log(error);
      // seterror(error.message);
      // setopen(true);
    }
  };

  return (
    <>
      <div className="Login_box">
        <h1 className="Login_title">Sign In</h1>
        {/* <p className="Login_describe">
          Lorem ipsum dolor sit amet consectetur. Arcu odio sit dis magnis
          elementum nisl est integer laoreet.{" "}
        </p> */}
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className="Login_form"
        >
          <input
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email Address"
            className="auth_inputfield"
            type="email"
            name="email"
          />
          {formik.errors.email ? (
            <div className="validation_error">{formik.errors.email}</div>
          ) : null}
          <div>
            <input
              onChange={formik.handleChange}
              value={formik.values.password}
              placeholder="Password"
              className="auth_inputfield"
              type="text"
              name="password"
            />
            {formik.errors.password ? (
              <div className="validation_error">{formik.errors.password}</div>
            ) : null}
          </div>
          {/* <p
            className="forget_button"
            onClick={() => navigate("/ForgetPassword")}
          >
            Forgot Password ?
          </p> */}
          <button className="Auth_button" type="submit">
            Sign In{" "}
          </button>
        </form>
      </div>
    </>
  );
};
export default SignIn;
