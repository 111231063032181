import { Outlet } from "react-router-dom";
import Settingmenu from "./Settingmenu";
import Header from "../../Layout/Header";

const Settinglayout = () => {
  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">Setting</h1>
        <Header />
      </div>
      <div className="Dashboard_screen">
        <div className="Setting_layout">
          {" "}
          <Settingmenu />
          <div className="Settingbox">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default Settinglayout;
