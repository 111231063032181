import "./Transection.css";
import { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import profileimg from "../../assets/profile.png";
import Pagination from "../Pagination/Pagination";
import Loader from "../../Loader/Loader";
import { transectionlist } from "../../service/Mainservice";
import moment from "moment";

const Transection = () => {
  const [loader, setloader] = useState(true);
  const [list, setlist] = useState<any>([]);
  const [totalpage, settotalpage] = useState(1);
  const [currentpage, setcurrentpage] = useState(1);
  const [totalitem, settotalitem] = useState(0);

  useEffect(() => {
    getrequestlist("1");
  }, []);
  const getrequestlist = async (page: string) => {
    setloader(true);

    try {
      const response = await transectionlist(page);
      console.log(response);
      setloader(false);

      if (response.status === true) {
        setlist(response.data);
        settotalitem(response.totalItems);
        settotalpage(response.totalPages);
        setcurrentpage(response.currentPage);
      } else {
        setlist([]);
        settotalitem(0);
        settotalpage(1);
        setcurrentpage(1);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const startRange = (currentpage - 1) * 10 + 1;
  const endRange = Math.min(currentpage * 10, totalitem);

  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">
          <svg
            width="17"
            height="16"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83331 9H20.1666"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Transections
        </h1>
        <div className="header_search">
          <svg
            width="15"
            height="15"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <circle cx="8.01515" cy="8.51515" r="7.51515" stroke="#0A0F0F" />
              <path
                d="M13.6514 14.1514L15.9999 16.4999"
                stroke="#0A0F0F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>

          <input
            type="search"
            name="languagesearch"
            id="languagesearch"
            placeholder="Find the language you offer"
          />
        </div>
        <Header />
      </div>
      <div className="Dashboard_screen">
        <div className="Trasaction_main">
          <p className="Dashboard_order">Total Revenue </p>
          <h2 className="Dashboard_ordernum">
            {list.TotalTransaction} SAR <span>{list.TotalOrder} orders</span>
          </h2>
          <div className="Dashboard_overview">
            <div>
              <h4 className="Dashboard_overview_title">All Transections </h4>
              <p className="Transection_pagedatainfo">
                Showing results {startRange}-{endRange} of {totalitem}
              </p>
            </div>
            <div className="Transection_data">
              <p className="Transection_data_filter">
                Date Range
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M1 1L6.5 6L12 1"
                    stroke="#7C7C7D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
              <p className="Transection_data_filter">
                Activity
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M1 1L6.5 6L12 1"
                    stroke="#7C7C7D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
              <p className="Transection_data_export">
                Export Transection Reports
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7433 6.38965C15.775 6.65071 17.0129 8.20866 17.0129 11.6193V11.7288C17.0129 15.4931 15.5055 17.0005 11.7412 17.0005H6.25885C2.49451 17.0005 0.987091 15.4931 0.987091 11.7288V11.6193C0.987091 8.23392 2.20819 6.67597 5.18934 6.39807"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.00423 11.5259V1.94238"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8253 3.82017L9.0041 0.999023L6.18295 3.82017"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        {list?.Transactions?.length > 0 ? (
          <div className="Transaction_table_data">
            <table className="Transaction_table">
              <thead>
                <tr>
                  <th className="transection_head_title">Name</th>
                  <th>Date</th>
                  <th>Order Id</th>
                  <th>Status</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {list?.Transactions.map((data: any, index: number) => (
                  <tr>
                    <td className="table_userinfo">
                      <img src={data.trans_by_image} alt="" />
                      {data.trans_by_name}
                    </td>
                    <td>{moment(Number(data.startat)).format("ll")}</td>
                    <td>#58475588555</td>
                    <td>
                      {data.status === "Pending" ? (
                        <span className="pending_data">Pending</span>
                      ) : data.status === "Cancel" ? (
                        <span className="cancel_data">Suspended</span>
                      ) : data.status === "Complete" ? (
                        <span className="active_data">Complete</span>
                      ) : (
                        <span className="editor_data">In review</span>
                      )}
                    </td>
                    <td>
                      <span className="pending_amount">{data.price} SAR</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentpage}
              totalPages={totalpage}
              getorderlist={getrequestlist}
            />
          </div>
        ) : (
          <p className="nodata">No Transections</p>
        )}
      </div>
      {loader && <Loader />}
    </>
  );
};
export default Transection;
