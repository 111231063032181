import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import navlogo from "../../assets/Navlogo.png";
import { ChangeEvent, useState } from "react";
import { changestatus } from "../../service/Mainservice";
import Logout from "../../Layout/Logout";

const Decline = () => {
  const [msg, setmsg] = useState("");
  const navigate = useNavigate();

  const location = useLocation();

  const handledecline = async () => {
    try {
      const response = await changestatus(
        location.state.id,
        "Decline",
        location.state.field
      );
      console.log(response);
      navigate("/Dashboard");
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">
          {" "}
          <svg
            width="17"
            height="16"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => navigate("/Requests")}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83331 9H20.1666"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Decline With Message
        </h1>
        <Logout />
      </div>

      <div className="Dashboard_screen">
        <div className="Agency_box">
          <h1 className="preview_title">Decline with Message </h1>
          <p className="preview_describe">
            We have received your request and under revision. We will inform you
            as soon as possible, to check request state, login to your account
            with email and password
          </p>
          <textarea
            name="reason"
            id="reason"
            cols={30}
            rows={15}
            placeholder="Type your message here "
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setmsg(e.target.value)
            }
            className="auth_inputfield"
          ></textarea>

          <button className="decline_button" onClick={handledecline}>
            Decline Request
          </button>
        </div>
      </div>
    </>
  );
};
export default Decline;
