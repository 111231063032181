import React, { useState } from "react";
import { useFormik } from "formik";

interface Values {
  email: string;
}
const validate = (values: Values) => {
  const errors: Partial<Values> = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

const ForgetPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      // handlesubmit(values);
      console.log(values);
    },
  });

  return (
    <>
      <div className="Forget_box">
        <h1 className="Auth_title">Forget Password</h1>
        <p className="Login_describe">
          Lorem ipsum dolor sit amet consectetur. Arcu odio sit dis magnis
          elementum nisl est integer laoreet.{" "}
        </p>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className="Forget_form"
        >
          <input
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email Address"
            className="auth_inputfield"
            type="email"
            name="email"
          />
          {formik.errors.email ? (
            <div className="validation_error">{formik.errors.email}</div>
          ) : null}
        </form>
        <button className="Auth_button">Next</button>
      </div>
    </>
  );
};
export default ForgetPassword;
