import React from "react";
import { useNavigate } from "react-router-dom";
import "./Menu.css";

interface MenuProps {
  showmenu: React.Dispatch<React.SetStateAction<boolean>>;
  menuref: React.RefObject<HTMLDivElement>;
}

const Menu: React.FC<MenuProps> = ({ showmenu, menuref }) => {
  const navigate = useNavigate();

  const toprofile = () => {
    navigate("/Profile");
    // showmenu(false);
  };
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="modal" />
      <div className="menumodal_content" ref={menuref}>
        <div className="menu_drop" />
        <h1 className="menu_title">Profile Menu</h1>
        <div className="menu_detail" onClick={toprofile}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5284 14.7746C12.8894 14.7746 14.8034 12.8606 14.8034 10.4996C14.8034 8.13859 12.8894 6.22461 10.5284 6.22461C8.1674 6.22461 6.25342 8.13859 6.25342 10.4996C6.25342 12.8606 8.1674 14.7746 10.5284 14.7746Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 3.7832L7.14657 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 17.2168L7.14657 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 17.2168L13.863 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 3.7832L13.863 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <p>View Profile</p>
        </div>
        <div className="menu_detail" onClick={toprofile}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5284 14.7746C12.8894 14.7746 14.8034 12.8606 14.8034 10.4996C14.8034 8.13859 12.8894 6.22461 10.5284 6.22461C8.1674 6.22461 6.25342 8.13859 6.25342 10.4996C6.25342 12.8606 8.1674 14.7746 10.5284 14.7746Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 3.7832L7.14657 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 17.2168L7.14657 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 17.2168L13.863 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 3.7832L13.863 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <p>Setting</p>
        </div>
        <div className="menu_detail" onClick={toprofile}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5284 14.7746C12.8894 14.7746 14.8034 12.8606 14.8034 10.4996C14.8034 8.13859 12.8894 6.22461 10.5284 6.22461C8.1674 6.22461 6.25342 8.13859 6.25342 10.4996C6.25342 12.8606 8.1674 14.7746 10.5284 14.7746Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 3.7832L7.14657 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 17.2168L7.14657 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 17.2168L13.863 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 3.7832L13.863 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <p>Help & Support</p>
        </div>
        <div className="menu_detail" onClick={toprofile}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5284 14.7746C12.8894 14.7746 14.8034 12.8606 14.8034 10.4996C14.8034 8.13859 12.8894 6.22461 10.5284 6.22461C8.1674 6.22461 6.25342 8.13859 6.25342 10.4996C6.25342 12.8606 8.1674 14.7746 10.5284 14.7746Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 3.7832L7.14657 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 17.2168L7.14657 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 17.2168L13.863 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 3.7832L13.863 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <p>Security</p>
        </div>
        <div className="menu_detail" onClick={toprofile}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5284 14.7746C12.8894 14.7746 14.8034 12.8606 14.8034 10.4996C14.8034 8.13859 12.8894 6.22461 10.5284 6.22461C8.1674 6.22461 6.25342 8.13859 6.25342 10.4996C6.25342 12.8606 8.1674 14.7746 10.5284 14.7746Z"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 3.7832L7.14657 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.78357 17.2168L7.14657 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 17.2168L13.863 13.8633"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.226 3.7832L13.863 7.1367"
                stroke="#7C7C7D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <p>LogOut</p>
        </div>
      </div>
    </>
  );
};
export default Menu;
