import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

interface MapProps {
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  markerPosition: google.maps.LatLngLiteral | null;
  setMarkerPosition: Dispatch<
    React.SetStateAction<google.maps.LatLngLiteral | null>
  >;
  center: google.maps.LatLngLiteral | null;
  // centerPosition: ; // Add centerPosition prop
}

const Map: React.FC<MapProps> = ({
  address,
  setAddress,
  markerPosition,
  setMarkerPosition,
  center,
}) => {
  console.log(markerPosition, center);
  return (
    <LoadScript googleMapsApiKey="AIzaSyA_viwXGfo9_uQpVidm7KGm_yduRorUia8">
      <GoogleMap
        center={center || { lat: 0, lng: 0 }}
        zoom={12}
        mapContainerStyle={{
          height: "330px",
          width: "100%",
          marginTop: "15px",
          borderRadius: "10px",
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
      <p className="auth_inputfield">{address}</p>{" "}
    </LoadScript>
  );
};

export default Map;
