import "./Order.css";
import Header from "../../Layout/Header";
import Timecount from "../Timecount/Timecount";
import { Link } from "react-router-dom";

const OrderDetailsChat = () => {
  return (
    <>
      <div>
        <div className="Dashboard_header">
          <h1 className="header_title">
            <svg
              width="17"
              height="16"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
                stroke="#0A0A14"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.83331 9H20.1666"
                stroke="#0A0A14"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            #58751784
          </h1>
          <div className="header_search">
            <svg
              width="15"
              height="15"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <circle
                  cx="8.01515"
                  cy="8.51515"
                  r="7.51515"
                  stroke="#0A0F0F"
                />
                <path
                  d="M13.6514 14.1514L15.9999 16.4999"
                  stroke="#0A0F0F"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>

            <input
              type="search"
              name="languagesearch"
              id="languagesearch"
              placeholder="Find the language you offer"
            />
          </div>
          <Header />
        </div>
        <div className="Dashboard_main">
          <div className="Dashboard_left">
            <div className="Order_details_menu">
              <Link to="/Orderdetails" className="order_menuname ">
                Details
              </Link>
              <Link to="/" className="order_menuname">
                Requirement
              </Link>
              <Link
                to="/Orderdetails_Chat"
                className="order_menuname active_menuname"
              >
                Chats
              </Link>
            </div>
            <div className="Order_chat">
              <div className="Order_chatdata">
                <div
                  className="senderchat"
                  // className={
                  //   message.sender_id !== id
                  //     ? "d-flex flex-row justify-content-start senderchat"
                  //     : "d-flex flex-row justify-content-end mb-4 pt-1 mychat"
                  // }
                >
                  <img src="/orderprofile.png" className="chat_profileimg" />

                  <div className="senderchatbox">
                    {/* {message.imgs.length != 0 && (
                            <img
                              src={base + message.imgs}
                              style={{ width: "100px", height: "70px" }}
                            />
                          )} */}
                    {/* {message.msg === null ||
                            (message.msg.length != 0 && ( */}
                    <p
                      className="sendermsg"
                      // className={
                      //   message.sender_id !== id
                      //     ? "small p-2 ms-3 mb-1 rounded-3"
                      //     : "small p-2 me-3 mb-1 text-white rounded-3 bg-primary"
                      // }
                    >
                      {/* {message.msg} */}
                      Great! use this, it will be more effective, Thanks!
                    </p>
                    {/* ))} */}
                    <p
                      className="chat_time"
                      // ref={messagesEnd}
                    >
                      Today at 12:00 AM
                      {/* {new Date(message.timestamp).toLocaleTimeString(
                              [],
                              { hour: "2-digit", minute: "2-digit" }
                            )} */}
                    </p>
                  </div>
                </div>
                <div
                  className="mychat"
                  // className={
                  //   message.sender_id !== id
                  //     ? "d-flex flex-row justify-content-start senderchat"
                  //     : "d-flex flex-row justify-content-end mb-4 pt-1 mychat"
                  // }
                >
                  <img src="/orderprofile.png" className="chat_profileimg" />

                  <div className="mychatbox">
                    {/* {message.imgs.length != 0 && (
                            <img
                              src={base + message.imgs}
                              style={{ width: "100px", height: "70px" }}
                            />
                          )} */}
                    {/* {message.msg === null ||
                            (message.msg.length != 0 && ( */}
                    <p
                      className="mymsg"
                      // className={
                      //   message.sender_id !== id
                      //     ? "small p-2 ms-3 mb-1 rounded-3"
                      //     : "small p-2 me-3 mb-1 text-white rounded-3 bg-primary"
                      // }
                    >
                      {/* {message.msg} */}
                      Please let me know if you have any questions, I will be
                      there for you !{" "}
                    </p>
                    {/* ))} */}
                    <p
                      className="chat_time"
                      // ref={messagesEnd}
                    >
                      Today at 12:00 AM
                      {/* {new Date(message.timestamp).toLocaleTimeString(
                              [],
                              { hour: "2-digit", minute: "2-digit" }
                            )} */}
                    </p>
                  </div>
                </div>
                <div
                  className="mychat"
                  // className={
                  //   message.sender_id !== id
                  //     ? "d-flex flex-row justify-content-start senderchat"
                  //     : "d-flex flex-row justify-content-end mb-4 pt-1 mychat"
                  // }
                >
                  <img src="/orderprofile.png" className="chat_profileimg" />

                  <div className="mychatbox">
                    {/* {message.imgs.length != 0 && (
                            <img
                              src={base + message.imgs}
                              style={{ width: "100px", height: "70px" }}
                            />
                          )} */}
                    {/* {message.msg === null ||
                            (message.msg.length != 0 && ( */}
                    <p
                      className="mymsg"
                      // className={
                      //   message.sender_id !== id
                      //     ? "small p-2 ms-3 mb-1 rounded-3"
                      //     : "small p-2 me-3 mb-1 text-white rounded-3 bg-primary"
                      // }
                    >
                      {/* {message.msg} */}
                      Lorem ipsum dolor sit amet consectetur. Tortor adipiscing
                      ut viverra ornare curabitur nisi. Sed et euismod id ac
                      massa facilisi imperdiet sed quisque. Neque neque amet
                      quisque non justo molestie sit eget. Tincidunt feugiat
                      bibendum nulla scelerisque. Varius tristique tellus scele{" "}
                    </p>
                    {/* ))} */}
                    <p
                      className="chat_time"
                      // ref={messagesEnd}
                    >
                      Today at 12:00 AM
                      {/* {new Date(message.timestamp).toLocaleTimeString(
                              [],
                              { hour: "2-digit", minute: "2-digit" }
                            )} */}
                    </p>
                  </div>
                </div>
                <div
                  className="senderchat"
                  // className={
                  //   message.sender_id !== id
                  //     ? "d-flex flex-row justify-content-start senderchat"
                  //     : "d-flex flex-row justify-content-end mb-4 pt-1 mychat"
                  // }
                >
                  <img src="/orderprofile.png" className="chat_profileimg" />

                  <div className="senderchatbox">
                    {/* {message.imgs.length != 0 && (
                            <img
                              src={base + message.imgs}
                              style={{ width: "100px", height: "70px" }}
                            />
                          )} */}
                    {/* {message.msg === null ||
                            (message.msg.length != 0 && ( */}
                    <p
                      className="sendermsg"
                      // className={
                      //   message.sender_id !== id
                      //     ? "small p-2 ms-3 mb-1 rounded-3"
                      //     : "small p-2 me-3 mb-1 text-white rounded-3 bg-primary"
                      // }
                    >
                      {/* {message.msg} */}
                      Lorem ipsum dolor sit amet consectetur. Senectus quam sed
                      condimentum mattis elit quam urna. Est morbi ut sed
                      feugiat etiam proin{" "}
                    </p>
                    {/* ))} */}
                    <p
                      className="chat_time"
                      // ref={messagesEnd}
                    >
                      Today at 12:00 AM
                      {/* {new Date(message.timestamp).toLocaleTimeString(
                              [],
                              { hour: "2-digit", minute: "2-digit" }
                            )} */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Order_chat_input">
              <input
                type="text"
                name="chat"
                id="chat"
                placeholder="Type your message here"
              />
              <svg
                width="45"
                height="45"
                viewBox="28 23 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_235_487)">
                  <rect
                    x="28"
                    y="24"
                    width="44"
                    height="44"
                    rx="22"
                    fill="#2C7873"
                  />
                </g>
                <path
                  d="M45.433 41.5676L52.2389 39.299C55.2931 38.2809 56.9525 39.9483 55.9424 43.0025L53.6738 49.8084C52.1507 54.3858 49.6496 54.3858 48.1265 49.8084L47.4531 47.7883L45.433 47.1149C40.8557 45.5918 40.8557 43.0987 45.433 41.5676Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M47.605 47.4433L50.4748 44.5654"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <filter
                    id="filter0_d_235_487"
                    x="0"
                    y="0"
                    width="75"
                    height="70"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="14" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0695603 0 0 0 0 0.0360243 0 0 0 0 0.104167 0 0 0 0.03 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_235_487"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_235_487"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
          <div className="Dashboard_right">
            <Timecount />
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderDetailsChat;
