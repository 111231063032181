// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth}  from 'firebase/auth'
import { getMessaging } from "firebase/messaging";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDqy2nY_zuFqdsis_8C011hDPU-D3JUeqY",
  authDomain: "tarjim-b2e5b.firebaseapp.com",
  projectId: "tarjim-b2e5b",
  storageBucket: "tarjim-b2e5b.appspot.com",
  messagingSenderId: "584526876178",
  appId: "1:584526876178:web:a0602cc83fdb66227d52cb",
  measurementId: "G-8Y8SJE59JF",
  messagingServiceWorkerUrl: '/firebase-messaging-sw.js',


};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app)
export const auth=getAuth()