import "./Timecount.css";
import React, { useEffect, useState } from "react";
import "./Timecount.css";
// import { statuschange } from "../Service/Mainservice";

const Timecount: React.FC<any> = ({ data, getdetails }) => {
  const [deadline, setDeadline] = useState<any>();
  const [remainingTime, setRemainingTime] = useState<number>(0);
  let date = new Date();

  useEffect(() => {
    const deadlineDate = new Date(data.order_deadline).getTime();
    setDeadline(deadlineDate);
    // Initial calculation
    if (deadlineDate) {
      updateRemainingTime(deadlineDate);
    }

    // Update every second
    const interval = setInterval(() => updateRemainingTime(deadlineDate), 1000);

    // Cleanup on unmount
    return () => clearInterval(interval);
  }, [data.order_deadline]);

  const updateRemainingTime = (deadlineDate: number) => {
    const milliseconds = deadlineDate - new Date().getTime();

    if (milliseconds > 0) {
      setRemainingTime(milliseconds);
    }
  };

  const totalSeconds = Math.floor(remainingTime / 1000);
  console.log(totalSeconds);

  const days = Math.floor(totalSeconds / (24 * 60 * 60));
  const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const second = totalSeconds % 60;

  // const handlesubmit = async () => {
  //   try {
  //     const response = await statuschange("2", value, data.order_no, "");
  //     setconfirmmodal(false);
  //     getdetails();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handlecomplete = async () => {
  //   try {
  //     const value = {
  //       hour: "",
  //       day: "",
  //       price: "",
  //     };
  //     const response = await statuschange("5", value, data.order_no, urldoc);
  //     setuploadmodal(false);
  //     getdetails();
  //     setdoc(undefined);
  //     seturldoc("");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <div className="Timecounter">
        {data.status === 2 ? (
          <h3 className="Timecounter_await">Awaiting Client Payment</h3>
        ) : data.status === 4 ? (
          <h3 className="Timecounter_await">Order Cancelled</h3>
        ) : (
          // ) : data.status === 5 ? (
          //   <h3 className="Timecounter_await">Order Completed</h3>
          <>
            {" "}
            <h3>
              {data.status === 1
                ? "Enter Time of Delivery"
                : "Time left to deliver"}
            </h3>
            <div className="Time_num">
              <div>
                <h1 className="Time_count">{days}</h1>
                <p className="Time_countname">Days</p>
              </div>
              <hr />
              <div>
                <h1 className="Time_count">{hours}</h1>
                <p className="Time_countname">Hours</p>
              </div>
              <hr />
              <div>
                <h1 className="Time_count">
                  {data.status === 1 ? "0" : minutes}
                </h1>
                <p className="Time_countname">Minutes</p>
              </div>
              <hr />
              <div>
                <h1 className="Time_count">
                  {data.status === 1 ? "0" : second}
                </h1>
                <p className="Time_countname">Seconds</p>
              </div>
            </div>
            <button className="Time_button">Take an Action</button>
            <a href="" className="Time_link">
              Send Notification Alert
            </a>
          </>
        )}
      </div>
    </>
  );
};
export default Timecount;

// const Timecount = () => {
//   return (
//     <>
//       <div className="Timecounter">
//         <h3>Time left to deliver</h3>
//         <div className="Time_num">
//           <div>
//             <h1 className="Time_count">10</h1>
//             <p className="Time_countname">Days</p>
//           </div>
//           <hr />
//           <div>
//             <h1 className="Time_count">21</h1>
//             <p className="Time_countname">Hours</p>
//           </div>
//           <hr />
//           <div>
//             <h1 className="Time_count">57</h1>
//             <p className="Time_countname">Minutes</p>
//           </div>
//           <hr />
//           <div>
//             <h1 className="Time_count">24</h1>
//             <p className="Time_countname">Secounds</p>
//           </div>
//         </div>
//         <button className="Time_button">Take an Action</button>
//         <a href="" className="Time_link">
//           Send Notification Alert
//         </a>
//       </div>
//     </>
//   );
// };
// export default Timecount;
