import MainLayout from "../Layout/MainLayout";
import Editprofile from "../component/Profile/EditProfile";
import Profile from "../component/Profile/Profile";
import Dashboard from "../component/Dashboard/Dashboard";
import ChangePassword from "../component/Profile/ChangePassword";
import ProtectedRoute from "../Protected Routes/ProtectedRoute";
import OrderDetailsChat from "../component/Orderdetails/Orderdetails_Chat";
import Transection from "../component/Transection/Transection";
import OrderDetails from "../component/Orderdetails/Orderdetails";
import Orderreport from "../component/Orderdetails/Orderdetails_report";
import Allrequest from "../component/Allrequest/Allrequest";
import Orders from "../component/Orders/Order";
import Activeticket from "../component/Activetickets/Activeticket";
import Users from "../component/User/User";
import Agency from "../component/Agency/Agency";
import Agencydetails from "../component/Agency/AgencyDetails";
import Userdetails from "../component/User/Userdetails";
import Reports from "../component/Reports/Report";
import Settinglayout from "../component/Setting/Settinglayout";
import Notification from "../component/Setting/Notification";
import Agencypreview from "../component/Dashboard/Agencypreview";
import Ticketdetails from "../component/Activetickets/ActiveticketsDetails";
import Decline from "../component/Dashboard/Decline";
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: "/AgencyRequest_preview",
      element: <Agencypreview />,
    },
    {
      path: "/DeclineWithMessage",
      element: <Decline />,
    },

    {
      path: "/Dashboard",
      element: <Dashboard />,
    },
    {
      path: "/Profile",
      element: <Profile />,
    },
    // {
    //   path: "/EditProfile",
    //   element: <Editprofile />,
    // },
    // {
    //   path: "/ChangePassword",
    //   element: <ChangePassword />,
    // },
    {
      path: "/OrderDetails",
      element: <OrderDetails />,
    },
    {
      path: "/OrderDetails_Chat",
      element: <OrderDetailsChat />,
    },
    {
      path: "/OrderDetails_Report",
      element: <Orderreport />,
    },
    {
      path: "/Transection",
      element: <Transection />,
    },
    {
      path: "/Requests",
      element: <Allrequest />,
    },
    {
      path: "/Orders",
      element: <Orders />,
    },
    {
      path: "/Tickets",
      element: <Activeticket />,
    },
    {
      path: "/Ticketdetails",
      element: <Ticketdetails />,
    },
    {
      path: "/Transection",
      element: <Transection />,
    },
    {
      path: "/Userdetails",
      element: <Userdetails />,
    },
    {
      path: "/Users",
      element: <Users />,
    },

    {
      path: "/Agency",
      element: <Agency />,
    },
    {
      path: "/Agencydetails",
      element: <Agencydetails />,
    },
    {
      path: "/Transections",
      element: <Transection />,
    },
    {
      path: "/Reports",
      element: <Reports />,
    },
    {
      path: "/Setting",
      element: <Settinglayout />,
      children: [
        {
          path: "Notification",
          element: <Notification />,
        },
      ],
    },
  ],
};

// const MainRoutesWithProtection = {
//   element: <MainLayout />,
//   children: MainRoutes.children.map((route) => ({
//     ...route,
//     element: <ProtectedRoute>{route.element}</ProtectedRoute>,
//   })),
// };

// export default MainRoutesWithProtection;
export default MainRoutes;
