import "./Loader.css";
import { BallTriangle } from "react-loader-spinner";

const Loader = () => (
  <div className="loaderdiv">
    <BallTriangle
      height={70}
      width={60}
      radius={5}
      color="var(--appcolor)"
      ariaLabel="ball-triangle-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  </div>
);

export default Loader;
