import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import ticketprofile from "../../assets/ticketimg.png";

import Header from "../../Layout/Header";
import projectimg from "../../assets/Projectimg.png";
import myprofile from "../../assets/myprofile.png";
import review1 from "../../assets/Review1.png";
import usa from "../../assets/usa.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ticketdetails } from "../../service/Mainservice";
import moment from "moment";
import Loader from "../../Loader/Loader";

const Ticketdetails = () => {
  const [loader, setloader] = useState(true);
  const [list, setlist] = useState<any>([]);
  const location = useLocation();

  useEffect(() => {
    getrequestlist(location.state.id);
  }, []);

  const getrequestlist = async (id: string) => {
    try {
      const response = await ticketdetails(id);
      setloader(false);
      if (response.status === true) {
        setlist(response.data[0].TicketData);
      } else {
        setlist([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  console.log(list);
  const navigate = useNavigate();
  return (
    <>
      <div className="Mainheader">
        <h1 className="header_title">
          <svg
            width="17"
            height="16"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => navigate("/Tickets")}
            style={{ cursor: "pointer" }}
          >
            <path
              d="M8.96294 1.87012L1.83331 8.99975L8.96294 16.1294"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.83331 9H20.1666"
              stroke="#0A0A14"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Ticket Details
        </h1>
        {/* <Header /> */}
      </div>
      <div className="Dashboard_screen">
        <div className="agency_main">
          <div className="agencymain_left">
            <div className="Ticket_header">
              <div className="Ticket_transation_user">
                <img src={ticketprofile} alt="" />
                <div className="Ticket_transation_userdetail">
                  <h2 className="Ticket_transation_username">
                    {list.UserName}
                  </h2>
                  <div className="Ticket_transation_datestatus">
                    <p>{moment(Number(list.TicketCreatAT)).format("ll")}</p>
                  </div>
                </div>
              </div>
              <div>
                {" "}
                {list.Status === "Pending" ? (
                  <span className="pending_data">Pending</span>
                ) : list.Status === "Closed" ? (
                  <span className="cancel_data">Closed</span>
                ) : list.Status === "Open" ? (
                  <span className="appactive_data">Active</span>
                ) : (
                  <span className="editor_data">New</span>
                )}
              </div>
            </div>
            <div className="Ticket_titlediv">
              <h3>{list.Subcategoryategory}</h3>
            </div>
            <p className="agency_aboutdata">{list.TicketDesc} </p>
            <div className="ticket_attach">
              <svg
                width="16"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.525 9.4175L8.055 11.8875C6.685 13.2575 6.685 15.4675 8.055 16.8375C9.425 18.2075 11.635 18.2075 13.005 16.8375L16.895 12.9475C19.625 10.2175 19.625 5.7775 16.895 3.0475C14.165 0.3175 9.725 0.3175 6.995 3.0475L2.755 7.2875C0.415 9.6275 0.415 13.4275 2.755 15.7775"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              attachment-20231102-1-12ivo9g- 01.jpg100 KB
            </div>
            <div className="ticket_attach">
              <svg
                width="16"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.525 9.4175L8.055 11.8875C6.685 13.2575 6.685 15.4675 8.055 16.8375C9.425 18.2075 11.635 18.2075 13.005 16.8375L16.895 12.9475C19.625 10.2175 19.625 5.7775 16.895 3.0475C14.165 0.3175 9.725 0.3175 6.995 3.0475L2.755 7.2875C0.415 9.6275 0.415 13.4275 2.755 15.7775"
                  stroke="#2C7873"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              attachment-20231102-1-12ivo9g- 01.jpg100 KB
            </div>
          </div>
          <div className="agencymain_right">
            <div className="Ticket_alldetails">
              <div className="Ticket_titlediv2">
                <h3>Summery</h3>
              </div>
              <div className="ticket_summery">
                <p>ID</p>
                <h4>#{list.TicketNo}</h4>
              </div>
              <div className="ticket_summery">
                <p>Requester</p>
                <h4>{list.UserName} </h4>
              </div>
              <div className="ticket_summery">
                <p>Created</p>
                <h4>{moment(list.TicketCreatAT).calendar()}</h4>
              </div>
              <div className="ticket_summery">
                <p>Order Number</p>
                <h4>#{list.OrderId}</h4>
              </div>
              <div className="ticket_summery">
                <p>Order Value</p>
                <h4>{list.Price} SAR</h4>
              </div>
              <div className="ticket_summery">
                <p>Device name</p>
                <h4>Unknown</h4>
              </div>
              <div className="ticket_summery">
                <p>Chatbot Flow</p>
                <h4>--</h4>
              </div>
            </div>
            <div className="Ticket_alldetails">
              <div className="Ticket_titlediv2">
                <h3>Document</h3>
              </div>

              <div className="ticket_attach">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.525 9.4175L8.055 11.8875C6.685 13.2575 6.685 15.4675 8.055 16.8375C9.425 18.2075 11.635 18.2075 13.005 16.8375L16.895 12.9475C19.625 10.2175 19.625 5.7775 16.895 3.0475C14.165 0.3175 9.725 0.3175 6.995 3.0475L2.755 7.2875C0.415 9.6275 0.415 13.4275 2.755 15.7775"
                    stroke="#2C7873"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                attachment-20231102-1-12ivo9g- 01.jpg100 KB
              </div>
              <div className="ticket_attach">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.525 9.4175L8.055 11.8875C6.685 13.2575 6.685 15.4675 8.055 16.8375C9.425 18.2075 11.635 18.2075 13.005 16.8375L16.895 12.9475C19.625 10.2175 19.625 5.7775 16.895 3.0475C14.165 0.3175 9.725 0.3175 6.995 3.0475L2.755 7.2875C0.415 9.6275 0.415 13.4275 2.755 15.7775"
                    stroke="#2C7873"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                attachment-20231102-1-12ivo9g- 01.jpg100 KB
              </div>
              <div className="ticket_attach">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.525 9.4175L8.055 11.8875C6.685 13.2575 6.685 15.4675 8.055 16.8375C9.425 18.2075 11.635 18.2075 13.005 16.8375L16.895 12.9475C19.625 10.2175 19.625 5.7775 16.895 3.0475C14.165 0.3175 9.725 0.3175 6.995 3.0475L2.755 7.2875C0.415 9.6275 0.415 13.4275 2.755 15.7775"
                    stroke="#2C7873"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                attachment-20231102-1-12ivo9g- 01.jpg100 KB
              </div>
              <button className="ticket_button">Take an Action</button>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </>
  );
};
export default Ticketdetails;
