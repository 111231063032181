import axios from "axios";

const getToken = () => {
  return localStorage.getItem("atkn");
};

const headers = () => {
  const token = getToken();
  return {
    headers: {
      Authorization: `Bearer ${"" + token}`,
    },
  };
};

const dashboard = async () => {
  const params = {};
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}dashboard/get`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const requestlist = async (value: string) => {
  try {
    const params = {
      page: value,
      keyword: "",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}user/pending-agency-request-list`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const agencyreviewdetail = async (id: string) => {
  try {
    const params = {
      agency_id: `${id}`,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}user/get-agency-detail`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const changestatus = async (id: string, status: string, field: any) => {
  try {
    const params = {
      User_Id: `${id}`,
      Status: status,
      Decline_Message: field,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}user/change-agency-status`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const customerlist = async (value: string) => {
  try {
    const params = {
      page: value,
      keyword: "",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}user/customer-list`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const ticketlist = async (value: string) => {
  try {
    const params = {
      page: value,
      daterange: "",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}ticket/get-ticket-list`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const ticketdetails = async (value: string) => {
  try {
    const params = {
      TicketNo: value,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}ticket/get-ticket-detail`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const agencylist = async (value: string, lng: string) => {
  try {
    const params = {
      page: value,
      keyword: "",
      type: "All",
      language: lng,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}user/agency-list`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const orderlist = async (value: string, searchby: string, search: string) => {
  try {
    const params = {
      page: value,
      type: "",
      daterange: "",
      search_by: searchby,
      search: search,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}order/get-all`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const orderdetails = async (num: string) => {
  try {
    const params = {
      OrderNo: `${num}`,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}order/get-order-detail`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const transectionlist = async (value: string) => {
  try {
    const params = {
      page: value,
      daterange: "",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}transections/get-list`,
      params,
      headers()
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const profile = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}UpdateProfile`,
      headers()
    );
    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};
// const editprofile = async (data, image) => {
//   try {
//     const params = new FormData();
//     params.append("username", data.name);
//     {
//       image && params.append("profile_pic", image);
//     }

//     const response = await axios.put(
//       `${process.env.REACT_APP_BASE_URL}UpdateProfile`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error:any) {
//     throw error.response.data;
//   }
// };
const changepassword = async (values: {
  oldpassword: string;
  password: string;
}) => {
  try {
    const params = new FormData();
    params.append("old_password", values.oldpassword);
    params.append("new_password", values.password);

    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}Changepassword`,
      params,
      headers()
    );
    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};
// const dashboard = async () => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_BASE_URL}DashBoard`,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

// const usersearch = async (pageno, value) => {
//   try {
//     const params = {
//       page: pageno,
//       search_text: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}UserSearch`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const userdetails = async (value) => {
//   try {
//     const params = {
//       id: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}UserDetails`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const driverlist = async (value) => {
//   try {
//     const params = {
//       page: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}DriverList`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const driversearch = async (pageno, value) => {
//   try {
//     const params = {
//       page: pageno,
//       search_text: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}DriverSearch`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

// const driverdetails = async (value) => {
//   try {
//     const params = {
//       id: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}DriverDetails`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const active = async (value, setblock) => {
//   try {
//     const params = {
//       id: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}UnBlockUser`,
//       params,
//       headers()
//     );
//     setblock(response.data.data.is_block);
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const deactive = async (value, setblock) => {
//   try {
//     const params = {
//       id: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}BlockUser`,
//       params,
//       headers()
//     );
//     setblock(response.data.data.is_block);

//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

// const orderlist = async (value) => {
//   try {
//     const params = {
//       page: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}OrderTable`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const orderdetails = async (value) => {
//   try {
//     const params = {
//       id: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}OrderDetails`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

// const getdoc = async (value) => {
//   try {
//     const params = {
//       id: value,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}LegalDocsData`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };
// const updatedoc = async (value, lng, data) => {
//   try {
//     const params = {
//       id: value,
//       [lng]: data,
//     };
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}UpdateLegalDocsData`,
//       params,
//       headers()
//     );
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

export default profile;
export {
  dashboard,
  requestlist,
  agencyreviewdetail,
  changestatus,
  ticketlist,
  ticketdetails,
  orderlist,
  orderdetails,
  customerlist,
  agencylist,
  transectionlist,
  // editprofile,
  changepassword,
  // dashboard,
  // usersearch,
  // userdetails,
  // driverlist,
  // driversearch,
  // driverdetails,
  // active,
  // deactive,
  // orderlist,
  // orderdetails,
  // getdoc,
  // updatedoc,
};
